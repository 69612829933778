import React from 'react';
import {Form, Input, Select} from "antd";
import {Server} from "../../../../../../../servers/table/ServersTableTypes";
import {ServersTabsModalFormTypes} from "./ServersTabsModalFormTypes";

const { Option } = Select;

const ServersTabsModalForm: React.FC<ServersTabsModalFormTypes> = (props) => {

    return (
        <>
            <Form
                form={props.form}
                name={"products_add_server"}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name={"server"}
                    label={"Сервер"}
                    rules={[
                        { required: true, message: "Обязательное поле!" }
                    ]}
                >
                    <Select
                        placeholder={"Выберите сервер"}
                        allowClear
                    >
                        {props.serversList && props.serversList.map((server: Server) => {
                            return (
                                <Option value={server.id}>
                                    {server.host}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"port"}
                    label={"Порт"}
                    rules={[
                        {required: true, message: "Обязательное поле!"},
                    ]}
                >
                    <Input placeholder={"Например, 5321"} />
                </Form.Item>

            </Form>
        </>
    );
};

export default ServersTabsModalForm

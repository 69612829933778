import React, {useCallback} from "react";
import {ServersTablePropsTypes} from "./ServersTableTypes";
import {Space, Table, TableColumnsType, TablePaginationConfig} from "antd";
import {tablePaginationConfig} from "../../users/table/UsersTableTypes";
import {setPaginationFunction} from "../../../utils/pagination/setPaginationFunction";
import {Lead} from "../../leads/table/LeadsTableTypes";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import DropdownActions from "../../dropdown/dropdownMenu/DropdownActions";

const ServersTable: React.FC<ServersTablePropsTypes> = (props) => {

    const columnsTable: TableColumnsType<object> = [
        {
            title: " ",
            align: "center",
            width: '60px',
            key: 'dropdown',
            render: (lead: Lead) => (
                <Space size="middle">
                    <ErrorBoundary>
                        <DropdownActions
                            recordId={lead.id}
                            handleEdit={() => {
                                props.handleEdit(lead)
                            }}
                            handelDelete={(leadId: number) => {
                                props.handleDelete(leadId)
                            }}/>
                    </ErrorBoundary>
                </Space>
            ),
        },
        {
            title: 'Ip адрес',
            dataIndex: 'host',
            ellipsis: {
                showTitle: false
            },
        }]

    const handleChangePaginationTable = useCallback((paginationConfig: tablePaginationConfig) => {
        props.handleUpdateList && props.handleUpdateList(paginationConfig.current)
    },[props.handleUpdateList])

    return (
        <>
            <Table
                columns={columnsTable}
                dataSource={props.serversList}
                scroll={{x: 'max-content'}}
                loading={props.loading}
                onChange={(pagination: TablePaginationConfig) => handleChangePaginationTable(pagination as tablePaginationConfig) }
                pagination={setPaginationFunction(props.totalServers, props.page)}
                onRow={(lead) => {
                    return {
                        onDoubleClick: () => {
                            props.handleEdit(lead)
                        },
                    };
                }}
                size="small"
            />
        </>
    )
}

export default ServersTable

import React, {useState} from 'react';
import cl from "./buttons.module.css";
import classNames from 'classnames';
import {Link as RouterLink} from "react-router-dom";
import {tgBotLinkToAuthorization} from "../../../appVariables/appVariables";

const PrettyButton: React.FC<{}> = (props) => {
    const [isPressed, setIsPressed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const btnClass = classNames({
        [cl.button]: true,
        [cl.pressed]: isPressed,
        [cl.hovered]: !isPressed && isHovered
    });

    return (
        <>
            <RouterLink
                className={btnClass}
                onMouseDown={() => setIsPressed(true)}
                onMouseUp={() => setIsPressed(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                to={`${tgBotLinkToAuthorization}`}
            >
                <span className={classNames(cl.buttonCaption)}>
                    авторизоваться
                </span>
                <span className={classNames(cl.buttonIcon)}>
                    <svg width="258" height="214" viewBox="0 0 258 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2796 92.4983C87.3699 62.3967 133.441 42.5519 156.493 32.9639C222.31 5.58816 235.986 0.832698 244.9 0.67567C246.861 0.641134 251.245 1.12702 254.084 3.43111C256.482 5.37665 257.142 8.00479 257.457 9.84937C257.773 11.6939 258.166 15.896 257.854 19.1793C254.287 56.6546 238.854 147.597 231.003 189.57C227.68 207.331 221.139 213.286 214.806 213.868C201.043 215.135 190.592 204.773 177.262 196.035C156.402 182.361 144.618 173.85 124.371 160.507C100.972 145.087 116.141 136.612 129.476 122.761C132.966 119.137 193.606 63.9798 194.78 58.9762C194.926 58.3504 195.063 56.0178 193.677 54.786C192.291 53.5543 190.246 53.9755 188.77 54.3105C186.678 54.7853 153.355 76.8104 88.8019 120.386C79.3433 126.881 70.7761 130.045 63.1002 129.879C54.638 129.696 38.3603 125.095 26.2595 121.161C11.4173 116.337 -0.378897 113.786 0.648291 105.592C1.18331 101.324 7.06042 96.9598 18.2796 92.4983Z" fill="white"/>
                    </svg>
                </span>
            </RouterLink>
        </>
    );
};

export default PrettyButton;

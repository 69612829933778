import React from "react";
import {Header} from "antd/lib/layout/layout";
import {Button} from "antd";
import { MenuUnfoldOutlined,MenuFoldOutlined } from '@ant-design/icons';
import {useStylesToken} from "../../../theme/useStylesToken";
import {LayoutHeaderPropsTypes} from "./layoutHeaderTypes";


const LayoutHeader: React.FC<LayoutHeaderPropsTypes> = (props) => {
    const {token} = useStylesToken()

    return (
        <>
            <Header style={{ paddingLeft: '10px', backgroundColor: token.colorPrimary }}>
                <Button
                    type="text"
                    icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => props.setCollapsed(!props.collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                        color: token.textColorWhite
                    }}
                />
            </Header>
        </>
    )
}

export default LayoutHeader

import React, {useCallback, useEffect, useMemo} from "react";
import {FieldSetting, LicensesSettingsFormPropsTypes} from "./LicensesSettingsFormTypes";
import {Button, Col, Form, Input, Row, Switch, Tooltip} from "antd";
import moment from "moment";
import {CheckCircleOutlined} from "@ant-design/icons";
import useStateRef from "react-usestateref";

const LicensesSettingsForm: React.FC<LicensesSettingsFormPropsTypes> = (props) => {
    const [formSettings] = Form.useForm()
    const [,setChangedFormElementsKey,changedFormElementsKeyRef] = useStateRef<string[]>([])

    useEffect(() => {
        if (props.licenseSettingsList) {
            props.licenseSettingsList.map((field: FieldSetting) => {
                formSettings.setFieldsValue({
                    [`${field.key}`]: field.type === 'datetime' ? moment(field.value).format('YYYY-MM-DD') : field.value
                })
            })
        }
    },[props.licenseSettingsList])

    const handlePushToChangedFormElementsKeyList = useCallback((key: string) => {
        if (!key || changedFormElementsKeyRef.current.includes(key)) return
        const copyChangedFormElementsKey = [...changedFormElementsKeyRef.current]
        copyChangedFormElementsKey.push(key)
        setChangedFormElementsKey(copyChangedFormElementsKey)

    },[changedFormElementsKeyRef.current,setChangedFormElementsKey])

    const handleEditField = useCallback((fieldId: number, value: any, key: string) => {
        if (!fieldId) return

        props.handleEditFieldLicenseSetting && props.handleEditFieldLicenseSetting(fieldId,value)
        setChangedFormElementsKey(prevState => prevState.filter((item) => item !== key))

    },[props.handleEditFieldLicenseSetting])

    const getFormComponents = useCallback((fieldSetting: FieldSetting) => {
        const key = fieldSetting.key
        switch (fieldSetting.type) {
            case 'str':
                return (
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={fieldSetting.title}
                            name={key}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input
                                placeholder={fieldSetting.title}
                                onChange={() => {
                                    handlePushToChangedFormElementsKeyList(key)
                                }}
                            />
                        </Form.Item>
                    </Col>
                )
            case 'int':
                return (
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={fieldSetting.title}
                            name={key}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input
                                type={'number'}
                                placeholder={fieldSetting.title}
                                onChange={() => {
                                    handlePushToChangedFormElementsKeyList(key)
                                }}
                            />
                        </Form.Item>
                    </Col>
                )
            case 'bool':
                return (
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={fieldSetting.title}
                            name={key}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Switch
                                onChange={() => {
                                handlePushToChangedFormElementsKeyList(key)
                                }}
                            />
                        </Form.Item>
                    </Col>
                )
            case 'datetime':
                return (
                    <Col xs={24} lg={24} md={24}>
                        <Form.Item
                            label={fieldSetting.title}
                            name={key}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <input
                                onChange={() => {
                                    handlePushToChangedFormElementsKeyList(key)
                                }}
                                className={'ant-input base-antd-date-picker'}
                                type="date"
                                max="9999-12-31"
                                min={moment().format("DD-MM-YYYY")}
                                pattern="[0-2][0-9]"
                            />
                        </Form.Item>
                    </Col>
                )
        }
    },[handlePushToChangedFormElementsKeyList])

    const filteringLicenseSettingsBySelectedKey = useMemo(() => {
        if (!props.licenseSettingsList) return

        return props.licenseSettingsList.filter((item: FieldSetting) => props.editableKeys.includes(item.key))

    },[props.licenseSettingsList,props.editableKeys,formSettings])

    return (
        <>
            <Form
                form={formSettings}
                onChange={() => {
                    console.log('onChangeForm')
                }}
                layout="vertical"
            >
                <Row gutter={16}>
                    {
                        filteringLicenseSettingsBySelectedKey &&
                            filteringLicenseSettingsBySelectedKey.length > 0 &&
                                filteringLicenseSettingsBySelectedKey.map((fieldSetting: FieldSetting) => {
                                    return (
                                       <>
                                           <Col lg={changedFormElementsKeyRef.current.includes(fieldSetting.key) ? 22 : 24}>
                                               {getFormComponents(fieldSetting)}
                                           </Col>
                                           {changedFormElementsKeyRef.current.includes(fieldSetting.key) &&
                                           <Col style={{display: 'flex', alignItems: 'center', padding: '0'}}>
                                               <Tooltip title={'Сохранить'}>
                                                   <Button
                                                       style={{minHeight: '34px',marginTop: '7px'}}
                                                       icon={<CheckCircleOutlined/>}
                                                       onClick={() => {
                                                           if (fieldSetting.id != null) {
                                                               handleEditField(
                                                                   fieldSetting.id,
                                                                   formSettings.getFieldValue(fieldSetting.key),
                                                                   fieldSetting.key
                                                               )
                                                           }
                                                       }}
                                                   />
                                               </Tooltip>
                                           </Col>}
                                       </>
                                    )
                        })
                    }
                </Row>
            </Form>
        </>
    )
}

export default LicensesSettingsForm

import {message} from "antd";
import {removeToken} from "./ApiManager";

interface subcontextTextObjType {
    text: {text: string[]}
}

interface subcontextTypes {
    text: string | subcontextTextObjType,
    type: string
}

export default async function doTask<T>(func: () => Promise<T>) {
    try {
        const result = await func();
        return { result, success: true, error: null };
    } catch (error: any) {
        await showError(error?.response?.data);
        console.error(error?.response?.status)
        if (error?.response?.status === 401) {
            removeToken()
            window.location.reload();
        }
        return { result: null, success: false, error };
    }
}

async function showError (error: any): Promise<any> {
    if (typeof error?.data === "string") {
        message.error(error?.data)
        await printErrorInSubcontext(error?.subcontext)
        return
    }
    if (error?.data?.text) {
        message.error(error?.data?.text)
        await printErrorInSubcontext(error?.subcontext)
        return
    }

    if (error?.data?.data) {
        message.error(error?.data?.data)
        await printErrorInSubcontext(error?.subcontext)
        return
    }

    if (typeof error?.data === 'object') {
        Object.entries(error?.data).map(async (err) => {
            if (err[0] && err[1]) {
                message.error(`${err[0]} - ${err[1]}`)
            }
        })
        await printErrorInSubcontext(error?.subcontext)
        return
    }

    message.error('Ошибка запроса к серверу')
}


function printErrorInSubcontext (subcontext: subcontextTypes[] ) {
    if (subcontext?.length === 0) return
    subcontext.filter((itemError) => itemError.type === 'error').map(async (itemError) => {
        if (typeof itemError?.text === 'string') {
            await message.warning(`${itemError.text}`)
        }

        if (typeof itemError?.text === "object") {
            Object.entries(itemError.text).map(async (err) => {
                if (err[0] && err[1]) {
                    await message.warning(`${err[0]} - ${err[1]}`)
                }
            })
        }
        return
    })
}


import React, {useCallback, useState} from "react";
import {ProductsSettingsPropsTypes} from "./ProductsSettingsTypes";
import {Box} from "@material-ui/core";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import EditableTableProductsSettings from "./editableTable/EditableTableProductsSettings";
import useAsyncEffect from "use-async-effect";
import doTask from "../../../logic/UiManager";
import {
    deleteProductSettingField,
    editProductSettingField,
    getProductSettingsList,
    ProductSettingsFilters
} from "../../../logic/ProductsManager";
import {
    FieldSetting
} from "../../partners/modal/mainModal/tabsModal/licenses/licensesSettings/form/LicensesSettingsFormTypes";
import {Button, Form, message, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ProductsSettingsModal from "./modal/ProductsSettingsModal";
import useStateRef from 'react-usestateref'
import {calculationPageAfterActionFunction} from "../../../utils/pagination/calculationPageAfterActionFunction";
import dayjs from "dayjs";

const ProductsSettings: React.FC<ProductsSettingsPropsTypes> = (props) => {
    const [form] = Form.useForm()
    const [productSettingsList,setProductSettingsList,productSettingsListRef] = useStateRef<FieldSetting[]>([])
    const [totalProductSettings,setTotalProductSettings] = useState<number>(0)
    const [loading,setLoading] = useState<boolean>(false)
    const [page,setPage,pageRef] = useStateRef(1)
    const [visibleModalProductSetting,setVisibleModalProductSetting] = useState<boolean>(false)

    useAsyncEffect(async () => {
        await handleUpdateList(page, props.editableProduct?.id)
    },[])

    const callGetProductsSettingsList = async (params?: ProductSettingsFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getProductSettingsList(params)
            if (res?.data) {
                setProductSettingsList(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalProductSettings(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateList = useCallback(async (page: number, productId?: number) => {
        setPage(page)
        const params: ProductSettingsFilters = {page, product: productId}
        await callGetProductsSettingsList(params)
    },[page])

    const callEditFieldSetting = async (fieldSettingId: number,productId?: number, body?: object) => {
        await doTask(async () => {
            const res =  await editProductSettingField(fieldSettingId, productId, body)
            if (res) {
                message.success(`Поле успешно отредактировано`)
                await handleUpdateList(page,productId)
            }
        })
    }
    const handleEditFieldSetting = useCallback((fieldSettingId: number, productId?: number,recordType?: string) => {
        if (!fieldSettingId) return
        form.validateFields().then(async (values) => {
            const body = {
                ...values,
                value: recordType === 'datetime' ? dayjs(values.value).format('YYYY-MM-DD') : String(values.value)
            }
            await callEditFieldSetting(fieldSettingId,productId, body)
        }).catch((err) => {
            console.log(err)
        })
    },[page, props.editableProduct])

    const handleDeleteFieldSetting = useCallback(async (fieldSettingId: number, productId?: number) => {
        await doTask(async () => {
            if (!fieldSettingId) return
            const res = await deleteProductSettingField(fieldSettingId,productId)
            if (res) {
                message.success('Поле успешно удалено!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,productSettingsListRef.current?.length)
                await handleUpdateList(pageFromUpdate,props.editableProduct?.id)
            }
        })
    },[pageRef, props.editableProduct])

    const handleOpenModalSetting = useCallback(() => {
        setVisibleModalProductSetting(true)
    },[visibleModalProductSetting])

    const handleCloseModalSetting = useCallback(async () => {
        form.resetFields()
        setVisibleModalProductSetting(false)
        await handleUpdateList(page, props.editableProduct?.id)
    },[page,visibleModalProductSetting])

    return (
        <>
            <Box style={{minHeight: '451px', padding: '0 5px 5px 5px'}}>
                <Box style={{padding: '5px'}}>
                    <Row justify={'end'}>
                        <Button
                            onClick={() => {
                                handleOpenModalSetting()
                            }}
                            type={'primary'}
                            icon={<PlusOutlined/>}
                        >
                            Добавить
                        </Button>
                    </Row>
                </Box>
                <ErrorBoundary>
                    <EditableTableProductsSettings
                        form={form}
                        loading={loading}
                        page={page}
                        productSettingsList={productSettingsList}
                        total={totalProductSettings}
                        hideSettingsKeysList={['ENDLESS_WORK']}
                        handleUpdateList={async (page: number) => {
                            await handleUpdateList(page, props.editableProduct?.id)
                        }}
                        handleEditFieldSetting={async (fieldSettingId: number,recordType: string) => {
                            await handleEditFieldSetting(fieldSettingId, props.editableProduct?.id,recordType)
                        }}
                        handleDelete={async (fieldId: number) => {
                            await handleDeleteFieldSetting(fieldId, props.editableProduct?.id)
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ProductsSettingsModal
                        visible={visibleModalProductSetting}
                        productSettingsList={productSettingsList}
                        form={form}
                        editableProduct={props.editableProduct}
                        onCloseModal={async () => {
                            await handleCloseModalSetting()
                        }}
                     />
                </ErrorBoundary>
            </Box>
        </>
    )
}


export default ProductsSettings

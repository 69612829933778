import React, {useCallback, useState} from "react";
import {Helmet} from "react-helmet";
import {Box} from "@material-ui/core";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import Toolbar from "../components/UI/toolbar/Toolbar";
import ServersTable from "../components/servers/table/ServersTable";
import ServerModal from "../components/servers/modal/ServerModal";
import useAsyncEffect from "use-async-effect";
import {defaultFilters} from "../logic/types/ApiTypes";
import doTask from "../logic/UiManager";
import {deleteServer, getServers} from "../logic/ServersManager";
import {Server} from "../components/servers/table/ServersTableTypes";
import {message} from "antd";
import useStateRef from 'react-usestateref'
import {calculationPageAfterActionFunction} from "../utils/pagination/calculationPageAfterActionFunction";

const ServersPage: React.FC = () => {
    const [serversList,setServersList,serversListRef] = useStateRef<Server[] | undefined>([])
    const [totalServers,setTotalServers] = useState<number>(0)
    const [page,setPage,pageRef] = useStateRef<number>(1)
    const [search,setSearch,searchRef] = useStateRef<string | undefined>('')
    const [loading,setLoading] = useState(false)
    const [visibleModalServer,setVisibleModalServer] = useState<boolean>(false)
    const [editableServer,setEditableServer] = useState<Server | null>(null)

    useAsyncEffect(async () => {
        await handleUpdateList(page)
    },[])

    const callGetServersList = async (params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getServers(params)
            if (res?.data) {
                setServersList(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalServers(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleOpenModalServer = useCallback(() => {
        setVisibleModalServer(true)
    },[visibleModalServer])

    const handleCloseModalServer = useCallback(async () => {
        setVisibleModalServer(false)
        setEditableServer(null)
        await handleUpdateList(page,search)
    },[page,search,visibleModalServer])

    const handleUpdateList = useCallback(async (page: number, search?: string | undefined) => {
        setPage(page)
        setSearch(search)
        const params: defaultFilters = {page}
        if (search) {
            params['search'] = search
        }
        await callGetServersList(params)
    },[page,search])

    const handleEditServer = useCallback((server: Server) => {
        if (!server) return
        setVisibleModalServer(true)
        setEditableServer(server)
    },[editableServer,visibleModalServer])

    const handleDeleteServer = useCallback(async (serverId: number) => {
        await doTask(async () => {
            if (!serverId) return
            const res = await deleteServer(serverId)
            if (res) {
                message.success('Сервер успешно удален!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,serversListRef.current?.length)
                await handleUpdateList(pageFromUpdate,searchRef.current)
            }
        })
    },[pageRef,searchRef])

    return (
        <>
            <Helmet>
                <title>Сервера | QT-Админ</title>
            </Helmet>

            <Box style={{padding: '25px'}}>
                <ErrorBoundary>
                    <Toolbar
                        loading={false}
                        handleUpdate={async (searchKeyword?: string) => {
                            await handleUpdateList( searchKeyword && searchKeyword?.length > 0 ? 1 : pageRef.current, searchKeyword)
                        }}
                        placeholder={'Поиск серверов (Адрес, порт)'}
                        openModal={() => {
                            handleOpenModalServer()
                        }}

                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ServersTable
                        serversList={serversList}
                        loading={loading}
                        page={page}
                        totalServers={totalServers}
                        handleDelete={async (serverId: number) => {
                            await handleDeleteServer(serverId)
                        }}
                        handleEdit={(server: Server) => {
                            handleEditServer(server)
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ServerModal
                        visible={visibleModalServer}
                        editableServer={editableServer}
                        onCloseModal={async () => {
                            await handleCloseModalServer()
                        }}
                    />
                </ErrorBoundary>
            </Box>
        </>
    )
}

export default ServersPage

import React from "react";
import {LicenseModalFormPropsTypes} from "./LicenseModalFormTypes";
import {Col, Form, Row, Select, Switch} from "antd";
import {LicensesTemplate} from "../../licensesTemplates/LicensesTemplateTypes";
import {defaultSelectedProductTemplateId} from "../../../../../../../../appVariables/appVariables";

const { Option } = Select;


const LicenseModalForm: React.FC<LicenseModalFormPropsTypes> = (props) => {

    return (
        <>
            <Form
                form={props.form}
                initialValues={{
                    product: defaultSelectedProductTemplateId ? Number(defaultSelectedProductTemplateId) : undefined,
                    demo_data: true
                }}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} lg={20} md={20}>
                        <Form.Item
                            label={"Шаблон продукта"}
                            name={"product"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Select placeholder={'Шаблон продукта'}>
                                {props.licenseTemplates && props.licenseTemplates.map((licenseTemplate: LicensesTemplate) => {
                                    return (
                                        <Option value={licenseTemplate.id}>
                                            {licenseTemplate.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={4} xs={4}>
                        <Form.Item label="Демо">
                            <Form.Item name="demo_data">
                                <Switch />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default LicenseModalForm

import React from 'react';
import {ServerItemPropsTypes} from "./ServerItemTypes";
import classNames from "classnames";
import cl from "./serverItem.module.css";
import {Button, Card, Col, Row, Tag, Tooltip} from "antd";
import {StopOutlined} from "@ant-design/icons";

const ServersItem: React.FC<ServerItemPropsTypes> = (props) => {
    return (
        <>
            <Card
                className={classNames(cl.serverItem)}
                hoverable={true}
                onClick={() => {
                    props.handleEdit && props.handleEdit(props.server)
                }}
            >
                <Row>
                    <Col lg={12} md={12} xl={12} xs={12} className={classNames(cl.serversItemTitle)}>
                        <Row gutter={10}>
                            <Col>
                                id: {props.server?.id}
                            </Col>
                            <Col>
                                <Tooltip title={"Порт"}>
                                    <Tag color="default">
                                        {props.server?.port}
                                    </Tag>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} md={12} xl={12} xs={12}>
                        <Row gutter={10} justify={'end'}>
                            <Col>
                                <Tooltip title={'Удалить'}>
                                    <Button
                                        danger={true}
                                        type={"primary"}
                                        icon={<StopOutlined/>}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            props.handleDelete && props.handleDelete(props.server?.id)
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default ServersItem;

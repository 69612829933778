import {ApiTypeResponse, defaultFilters} from "./types/ApiTypes";
import {apiCall, HttpMethods} from "./ApiManager";

export async function getPartnersList (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/companies/partners/',params)
}

export async function getPartnerById (partnerId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, `/companies/partners/${partnerId}`)
}


export async function createPartner (body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/companies/partners/`, body)
}

export async function editPartner (partnerId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/companies/partners/${partnerId}/`, body)
}

export async function deletePartner (partnerId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/companies/partners/${partnerId}/`)
}

import React, {useEffect} from "react";
import cl from "../../components/login/login.module.css";
import {Content} from "antd/es/layout/layout";
import PrettyButton from "../../components/UI/buttons/PrettyButton";
import {getToken} from "../../logic/ApiManager";
import {useNavigate} from "react-router-dom";

const LoginPage:  React.FC<{}> = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const token = getToken()
        if (token) {
            navigate('/')
        }
    },[])

    return (
        <Content
            className={cl.wrapper}
        >
            <section className={cl.content}>
                <aside className={cl.body}>
                    <div className={cl.greetings}>
                        <img
                            className={cl.greetingsIcon}
                            alt="КвикТранс"
                            width="168"
                            src={"../static/svg/logo/qt_full_green.svg"}
                        />
                        <p className={cl.greetingsCaption}>
                            Авторизуйтесь, чтобы продолжить
                        </p>
                    </div>
                    <div className={cl.greetingsButton}>
                        <PrettyButton/>
                        <p className={cl.greetingsCaptionAfter}>
                            через Телеграм
                        </p>
                    </div>
                </aside>
            </section>
        </Content>
    )
}


export default LoginPage

import Sider from "antd/es/layout/Sider";
import {Menu} from "antd";
import Logo from "../logo/Logo";
import React, {useCallback, useEffect, useState} from "react";
import {
    CloudServerOutlined,
    FunnelPlotOutlined,
    LogoutOutlined,
    TeamOutlined,
    UserOutlined,
    RobotOutlined,
    ControlOutlined
} from '@ant-design/icons';
import {LeftSliderPropsTypes} from "./leftSliderTypes";
import {useLocation, useNavigate} from "react-router-dom";
import {removeToken} from "../../../logic/ApiManager";

const LeftSlider: React.FC<LeftSliderPropsTypes> = (props) => {
    const [menuSelectedValue, setMenuSelectedValue] = useState<string[]>([])
    const navigate = useNavigate();
    const location = useLocation()


    useEffect(() => {
        if (location?.pathname) {
            setMenuSelectedValue([`${location.pathname}`])
        }
    },[location])

    const navigateToPage = useCallback((path: string | undefined) => {
        if (!path) return

        if (path === 'logout') {
            logout()
            return
        }
        navigate(path)

    },[navigate])

    const logout = () => {
        removeToken()
        navigate('/login')
    }
    return (
        <>
            <Sider
                trigger={null}
                collapsible
                collapsed={props.collapsed}
                onMouseEnter={() => {
                    props.onMouseEnter()
                }}
                onMouseLeave={() => {
                    props.onMouseLeave()
                }}
            >
                <Logo collapsed={props.collapsed}/>
                <Menu
                    selectedKeys={menuSelectedValue}
                    style={{height: '100%'}}
                    mode="inline"
                    onSelect={(val) => navigateToPage(val?.key)}
                    defaultSelectedKeys={['/leads']}
                    items={[
                        {
                            key: '/leads',
                            icon: <FunnelPlotOutlined />,
                            label: 'Лиды',
                        },
                        {
                            key: '/partners',
                            icon: <TeamOutlined />,
                            label: 'Клиенты',
                        },
                        {
                            key: '/users',
                            icon: <UserOutlined />,
                            label: 'Пользователи',
                        },
                        {
                            key: '',
                            icon: <ControlOutlined />,
                            label: 'Технические',
                            children: [
                                {
                                    key: '/products',
                                    icon: <RobotOutlined />,
                                    label: 'Продукты',
                                },
                                {
                                    key: '/servers',
                                    icon: <CloudServerOutlined />,
                                    label: 'Сервера',
                                },
                            ]
                        },
                        {
                            key: 'logout',
                            icon: <LogoutOutlined />,
                            label: 'Выход',
                        }
                    ]}
                />
            </Sider>
        </>
    )
}

export default LeftSlider

import React, {useCallback} from "react";
import {tablePaginationConfig, User, UsersTablePropsTypes} from "./UsersTableTypes";
import {message, Space, Switch, Table, TablePaginationConfig} from "antd";
import type { TableColumnsType } from 'antd';
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import DropdownActions from "../../dropdown/dropdownMenu/DropdownActions";
import {setPaginationFunction} from "../../../utils/pagination/setPaginationFunction";
import doTask from "../../../logic/UiManager";
import {editUser} from "../../../logic/UsersManager";


const UsersTable: React.FC<UsersTablePropsTypes> = (props) => {

    const columnsTable: TableColumnsType<User> = [
        {
            title: " ",
            align: "center",
            width: '60px',
            render: (user: User) => (
                <Space size="middle">
                    <ErrorBoundary>
                        <DropdownActions
                            recordId={user.id}
                            handleEdit={() => {
                                props.handleEditUser(user)
                            }}
                            handelDelete={(userId: number) => {
                                props.handleDeleteUser(userId)
                            }}/>
                    </ErrorBoundary>
                </Space>
            ),
        },
        {
            title: 'Telegram',
            dataIndex: 'telegram',
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Активен',
            //dataIndex: 'active',
            render: (user: User) => {
                return (
                    <>
                        <Switch
                            value={user.active}
                            onChange={async (value) => {
                                await handleEditUserActive(user.id, value)
                            }}
                        />
                    </>
                )
            }
        }
    ]

    const handleEditUserActive = useCallback(async (userId: number, active: boolean) => {
        await doTask(async () => {
            const res =  await editUser(userId, {active})
            if (res) {
                message.success(`Пользователь успешно ${userId ? 'отредактирован' : 'добавлен'}`)
                props.handleUpdateList && props.handleUpdateList(props.page)
            }
        })
    },[props.handleUpdateList])

    const handleChangePaginationTable = useCallback((paginationConfig: tablePaginationConfig) => {
       props.handleUpdateList && props.handleUpdateList(paginationConfig.current)
    },[props.handleUpdateList])

    return (
        <>
            <Table
                columns={columnsTable}
                dataSource={props.usersList}
                scroll={{x: 'max-content'}}
                loading={props.loading}
                onChange={(pagination: TablePaginationConfig) => handleChangePaginationTable(pagination as tablePaginationConfig) }
                pagination={setPaginationFunction(props.totalUsers, props.page)}
                onRow={(user) => {
                    return {
                        onDoubleClick: () => {
                            props.handleEditUser(user)
                        },
                    };
                }}
                size="small"
            />
        </>
    )
}

export default UsersTable

import React, {useCallback} from "react";
import {Lead, LeadsTablePropsTypes} from "./LeadsTableTypes";
import {Space, Table, TableColumnsType, TablePaginationConfig, Tooltip} from "antd";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import DropdownActions from "../../dropdown/dropdownMenu/DropdownActions";
import {tablePaginationConfig} from "../../users/table/UsersTableTypes";
import {setPaginationFunction} from "../../../utils/pagination/setPaginationFunction";

const LeadsTable: React.FC<LeadsTablePropsTypes> = (props) => {
    const columnsTable: TableColumnsType<Lead> = [
        {
            title: " ",
            align: "center",
            width: '60px',
            key: 'dropdown',
            render: (lead: Lead) => (
                <Space size="middle">
                    <ErrorBoundary>
                        <DropdownActions
                            recordId={lead.id}
                            handleEdit={() => {
                                props.handleEdit(lead)
                            }}
                            handelDelete={(leadId: number) => {
                                props.handleDelete(leadId)
                            }}/>
                    </ErrorBoundary>
                </Space>
            ),
        },
        {
            title: 'Название компании',
            dataIndex: 'name',
            width: 300,
            ellipsis: {
                showTitle: false
            },
            render: (name) => (
                <Tooltip title={name}>
                    {name}
                </Tooltip>
            )
        },
        {
            title: 'Город',
            width: 150,
            dataIndex: 'city',
            key: 'city',
            ellipsis: {
                showTitle: false
            },
            render: (city) => (
                <Tooltip title={city}>
                    {city}
                </Tooltip>
            )
        },
        {
            title: 'Email',
            width: 200,
            key: 'email',
            dataIndex: 'email',
            ellipsis: {
                showTitle: false
            },
            render: (email) => (
                <Tooltip title={email}>
                    {email}
                </Tooltip>
            )
        },
        {
            title: 'Конктактное лицо',
            dataIndex: 'contact_person',
            key: 'contact_person',
            width: 200,
            ellipsis: {
                showTitle: false
            },
            render: (contact_person) => (
                <Tooltip title={contact_person}>
                    {contact_person}
                </Tooltip>
            )
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            width: '150px',
            ellipsis: {
                showTitle: false
            },
            render: (comment) => (
                <Tooltip title={comment}>
                    {comment}
                </Tooltip>
            )
        },
        /*{
            title: ' ',
            width: 150,
            align: "center",
            key: 'action',
            fixed: 'right',
            render: (lead: Lead) => (
                <Button
                    onClick={() => {
                        props.handleTranslationToPartners && props.handleTranslationToPartners(lead.id)
                    }}
                    icon={<SwapOutlined />}
                    type={"text"}
                >
                    В клиенты
                </Button>
            )
        },*/
    ]

    const handleChangePaginationTable = useCallback((paginationConfig: tablePaginationConfig) => {
        props.handleUpdateList && props.handleUpdateList(paginationConfig.current)
    },[props.handleUpdateList])

    return (
        <>
            <Table
                columns={columnsTable}
                dataSource={props.leadsList}
                scroll={{x: '1000px'}}
                loading={props.loading}
                onChange={(pagination: TablePaginationConfig) => handleChangePaginationTable(pagination as tablePaginationConfig) }
                pagination={setPaginationFunction(props.totalLeads, props.page)}
                onRow={(lead) => {
                    return {
                        onDoubleClick: () => {
                            props.handleEdit(lead)
                        },
                    };
                }}
                size="small"
            />
        </>
    )
}

export default LeadsTable

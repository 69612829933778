import React from "react";
import DropdownMenu from "./DropdownMenu";
import {EllipsisOutlined} from "@ant-design/icons";
import {DropdownPropsTypes} from "./DropdownTypes";
import {Dropdown} from "antd";

const DropdownActions: React.FC<DropdownPropsTypes> = (props) => {
    return (
        <>
            <Dropdown disabled={props.disabled} overlay={
                <DropdownMenu
                    recordId={props.recordId}
                    handleEdit={(recordId: number) => {
                        props.handleEdit(recordId)
                    }}
                    hideDeletion={props.hideDeletion}
                    handelDelete={(recordId: number) => {
                        props.handelDelete(recordId)
                    }}
                />}
                    trigger={["hover"]}
            >
                <EllipsisOutlined style={{cursor: "pointer", fontSize: "24px"}}/>
            </Dropdown>
        </>
    )
}

export default DropdownActions

import React, {useCallback, useEffect} from "react";
import {ServerModalPropsTypes} from "./ServerModalTypes";
import {Button, Form, message, Modal} from "antd";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import ServerModalForm from "./form/ServerModalForm";
import doTask from "../../../logic/UiManager";
import {createServer, editServer} from "../../../logic/ServersManager";


const ServerModal: React.FC<ServerModalPropsTypes> = (props) => {
    const [form] = Form.useForm()

    useEffect(() => {
        if (props.editableServer) {
            form.setFieldsValue({
                ...props.editableServer,
            })
        }
    },[props.editableServer])

    const handleCancelModal = useCallback(() => {
        form.resetFields()
        props.onCloseModal()
    },[props.visible])

    const callCreateOrEditServer = async (serverId: number | undefined, body: object) => {
        await doTask(async () => {
            const res = serverId ? await editServer(serverId, body) : await createServer(body)
            if (res) {
                message.success(`Сервер успешно ${serverId ? 'отредактирован' : 'добавлен'}`)
                handleCancelModal()
            }
        })
    }

    const handleCreateOrEditServer = () => {
        form.validateFields().then(async (values) => {
            const body = {
                ...values,
            }
            await callCreateOrEditServer(props.editableServer?.id,body)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={props.editableServer ? 'Редактировать сервер' : 'Добавить сервер'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                           await handleCreateOrEditServer()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <ServerModalForm form={form}/>
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default ServerModal

import {ApiTypeResponse, defaultFilters} from "./types/ApiTypes";
import {apiCall, HttpMethods} from "./ApiManager";

export interface ProductSettingsFilters {
    page?: number,
    product?: number
}
export async function getProductsList (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/products/products/',params)
}

export async function createProduct (body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/products/products/`, body)
}

export async function editProduct (productId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/products/products/${productId}/`, body)
}

export async function deleteProduct (productId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/products/products/${productId}/`)
}

export async function getProductSettingsList (params?: ProductSettingsFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, `/products/products/settings/`, params)
}

export async function createProductSettingField (body?: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/products/products/settings/`,body)
}

export async function editProductSettingField (fieldSettingId: number,productId?: number, body?: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/products/products/settings/${fieldSettingId}/?product=${productId}`,body)
}

export async function deleteProductSettingField (fieldSettingId: number,productId?: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/products/products/settings/${fieldSettingId}/?product=${productId}`)
}

export async function getProductServersList (productId: number, params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, `/servers/product_servers/?product=${productId}`,params)
}

export async function createProductServer (productId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/servers/product_servers/?product=${productId}`,body)
}

export async function editProductServer (editableServerId: number,productId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/servers/product_servers/${editableServerId}/?product=${productId}`,body)
}

export async function deleteProductServer (editableServerId: number,productId?: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/servers/product_servers/${editableServerId}/?product=${productId}`)
}


import React from "react";
import {BasicInformationProductFormPropsTypes} from "./BasicInformationProductFormTypes";
import {Col, Form, Input, Row} from "antd";


const BasicInformationProductForm: React.FC<BasicInformationProductFormPropsTypes> = (props) => {
    return (
        <>
            <Form
                form={props.form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Название продукта"}
                            name={"name"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Название продукта"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Кодовое название"}
                            name={"codename"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Кодовое название"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default BasicInformationProductForm

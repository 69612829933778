import React, {useCallback, useEffect} from "react";
import {ProductModalPropsTypes} from "./ProductModalTypes";
import {Button, Form, message, Modal} from "antd";
import ErrorBoundary from "../../../errorBoundary/ErrorBoundary";
import TabsModalProduct from "./tabsModalProducts/TabsModalProduct";
import doTask from "../../../../logic/UiManager";
import {createProduct, editProduct} from "../../../../logic/ProductsManager";

const ProductModal: React.FC<ProductModalPropsTypes> = (props) => {
    const [form] = Form.useForm()


    const handleCancelModal = useCallback(() => {
        form.resetFields()
        props.onCloseModal()
    },[props.visible])

    useEffect(() => {
        if (props.editableProduct) {
            form.setFieldsValue({
                ...props.editableProduct,
            })
        }
    },[props.editableProduct])

    const callCreateOrEditProduct = async (productId: number | undefined, body: object) => {
        await doTask(async () => {
            const res = productId ? await editProduct(productId, body) : await createProduct(body)
            if (res) {
                message.success(`Продукт успешно ${productId ? 'отредактирован' : 'добавлен'}`)
                handleCancelModal()
            }
        })
    }

    const handleCreateOrEditProduct = () => {
        form.validateFields().then(async (values) => {
            const body = {
                ...values,
            }
            await callCreateOrEditProduct(props.editableProduct?.id,body)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal
                width={1000}
                destroyOnClose={true}
                title={props.editableProduct ? 'Редактировать продукт' : 'Добавить продукт'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                            await handleCreateOrEditProduct()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <TabsModalProduct
                        form={form}
                        editableProduct={props.editableProduct}
                    />
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default ProductModal

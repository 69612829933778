

export const templateProductsSettings = [
    {
        "key": "PROJECT_BACKEND_URL",
        "value": "https://crm.quicklytrans.ru:8111/",
        "type": "str",
        "title": "Хост backend",
    },
    {
        "key": "ENDLESS_WORK",
        "value": "False",
        "type": "bool",
        "title": "Бесконечная работа",
    },
    {
        "key": "END_WORK_DATETIME",
        "value": "2024-01-01T15:04:05Z04:00",
        "type": "str",
        "title": "Дата начала работы",
    },
    {
        "key": "START_WORK_DATETIME",
        "value": "2025-01-01T15:04:05Z04:00",
        "type": "str",
        "title": "Дата конца работы",
    },
    {
        "key": "PRODUCT_ADMIN_TOKEN",
        "value": "62469056ccf2c54d0dc6f75b75f75db511df3640",
        "type": "str",
        "title": "Токен администратора",
    },
    {
        "key": "BASE_URL",
        "value": "http://127.0.0.1:3000/",
        "type": "str",
        "title": "Основой адрес",
    },
    {
        "key": "DATABASE_NAME",
        "value": "db",
        "type": "str",
        "title": "Название базы данных",
    },
    {
        "key": "DATABASE_USER",
        "value": "db",
        "type": "str",
        "title": "Пользователь базы данных",
    },
    {
        "key": "DATABASE_PASSWORD",
        "value": "12345678",
        "type": "str",
        "title": "Пароль от базы данных",
    },
    {
        "key": "DATABASE_HOST",
        "value": "localhost",
        "type": "str",
        "title": "Хост базы данных",
    },
    {
        "key": "DATABASE_PORT",
        "value": "5432",
        "type": "int",
        "title": "Порт базы данных",
    },
    {
        "key": "SECRET_KEY",
        "value": "django-insecure-brn75!8k-i)xv=w!zh$@2wegymk07i0w9^5ojyxe#+km58!ch!",
        "type": "str",
        "title": "Секрет",
    },
    {
        "key": "REDIS_HOST",
        "value": "localhost",
        "type": "str",
        "title": "Хост REDIS",
    },
    {
        "key": "REDIS_PORT",
        "value": "6379",
        "type": "int",
        "title": "Порт REDIS",
    },
    {
        "key": "REDIS_DATABASE",
        "value": "0",
        "type": "int",
        "title": "Номер базы данных REDIS",
    }]

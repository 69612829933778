import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import { ReactElement } from "react";
import LoginPage from "../pages/login/LoginPage";
import NotFoundPage from "../pages/staticPage/NotFoundPage";
import LeadsPage from "../pages/LeadsPage";
import PartnersPage from "../pages/PartnersPage";
import {getToken} from "../logic/ApiManager";
import ContinuationAuthorizationPage from "../pages/login/ContinuationAuthorizationPage";
import UsersPage from "../pages/UsersPage";
import ServersPage from "../pages/ServersPage";
import ProductsPage from "../pages/ProductsPage";

interface RequireAuthProps  {
    children: ReactElement
}

const RouterPages: React.FC<{}> = () => {
  return (
      <>
          <Routes>
              <Route path='/' element={
                  <RequireAuth>
                      <MainLayout/>
                  </RequireAuth>
              }>

                <Route path={'/leads'} element={
                    <RequireAuth>
                        <LeadsPage/>
                    </RequireAuth>
                }/>

                  <Route path={'/partners'} element={
                      <RequireAuth>
                          <PartnersPage/>
                      </RequireAuth>
                  }/>

                  <Route path={'/users'} element={
                      <RequireAuth>
                          <UsersPage/>
                      </RequireAuth>
                  }/>

                  <Route path={'/products'} element={
                      <RequireAuth>
                        <ProductsPage/>
                      </RequireAuth>
                  }/>
                  <Route path={'/servers'} element={
                      <RequireAuth>
                          <ServersPage/>
                      </RequireAuth>}
                  />
                  <Route path={'/'} element={<Navigate to="/leads"/>}/>
              </Route>
              <Route path={'/continuationAuthorization'} element={<ContinuationAuthorizationPage/>}/>
              <Route path={'/login'} element={<LoginPage/>}/>
              <Route path={'*'} element={
                  <RequireAuth>
                    <NotFoundPage/>
                  </RequireAuth>
              }/>
          </Routes>

      </>
  )
}

function RequireAuth({ children }: RequireAuthProps): ReactElement | null {
    let location = useLocation();
    if (!getToken()) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default  RouterPages

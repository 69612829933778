export default class ApiError extends Error {
  constructor (message, code) {
    if (!message) {
      message = `Error ${code}`
    }
    super(message)
    this.code = code
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }
  }
}



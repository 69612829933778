import React, {useCallback, useEffect, useMemo} from "react";
import {ProductsSettingsModalFormPropsTypes} from "./ProductsSettingsModalFormTypes";
import {Col, Form, Input, Row, Select} from "antd";
import {templateProductsSettings} from "../../templates/TemplateProductsSettings";
import {getInputNodeComponent} from "../../editableTable/EditableTableProductsSettings";

const { Option } = Select;

const ProductsSettingsModalForm: React.FC<ProductsSettingsModalFormPropsTypes> = (props) => {
   /* const templateFieldOptions = useMemo(() => {
        const copyTemplateProductsSettings = [...templateProductsSettings]
        return copyTemplateProductsSettings.filter((filed: FieldSetting) =>
            !props.productSettingsList?.some(e => e.key === filed.key))
    },[])*/
    const formType = Form.useWatch('type', props.form);
    const formValue = Form.useWatch('value',props.form)

    const templateFieldOptions = useMemo(() => {
        return [...templateProductsSettings]
    },[])

    useEffect(() => {
        if (formType === 'bool') {
            props.form.setFieldsValue({
                value: false
            })
        } else if (!formValue) {
            props.form.setFieldsValue({
                value: ''
            })
        }
    },[formType,formValue])

    const getComponentForFieldValue = useCallback((type: string) => {
        if (!type) return <Input placeholder={'Значение'}/>
        return getInputNodeComponent(type, 'Значение')
    },[formType])

    const typeOptions = [
        {
            value: 'bool',
            title: 'Логический'
        },
        {
            value: 'int',
            title: 'Числовой'
        },
        {
            value: 'str',
            title: 'Строковый'
        },
        {
            value: 'datetime',
            title: 'Дата'
        }
    ]

    const handleChangeTemplate = (key:  string) => {
        if (!key) {
            props.form.resetFields()
            return
        }
        const copyTemplateProductsSettings = [...templateProductsSettings]
        const field = copyTemplateProductsSettings.find(item=> item.key === key)
        if (field) {
            props.form.setFieldsValue(field)
        }
    }
    return (
        <>
            <Form form={props.form} layout={'vertical'}>
                <Row gutter={15}>
                    <Col lg={24} xs={24}>
                        <Form.Item
                            label={"Выбор из рекомендуемого шаблона"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Select
                                allowClear={true}
                                onChange={(value) => {
                                    handleChangeTemplate(value)
                                }}
                                placeholder={'Выбор из рекомендуемого шаблона'}>
                                {templateFieldOptions.map((item,index) => {
                                    return (
                                        <Option value={item.key} key={index}>
                                            {item.title}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={"Тип"}
                            name={"type"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Select placeholder={'Тип'}>
                                {typeOptions.map((item) => {
                                    return (
                                        <Option value={item.value}>
                                            {item.title}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={"Название"}
                            name={"title"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Название"}/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={"Ключ"}
                            name={"key"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Ключ"}/>
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            label={"Значение"}
                            name={"value"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            {getComponentForFieldValue(formType)}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
export default ProductsSettingsModalForm

import React, {useState} from "react";
import {Layout} from "antd";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import LeftSlider from "./leftSlider/LeftSlider";
import LayoutHeader from "./header/LayoutHeader";
import {Content} from "antd/es/layout/layout";
import {Outlet} from "react-router-dom";
import {useStylesToken} from "../../theme/useStylesToken";


const MainLayout: React.FC<{}> = () => {
    const {token} = useStylesToken()
    const [collapsed, setCollapsed] = useState<boolean>(true);

    return (
        <>
            <Layout style={{width: '100%', height: '100%'}}>
                <ErrorBoundary>
                    <LeftSlider
                        onMouseEnter={() => setCollapsed(false)}
                        onMouseLeave={() => setCollapsed(true)}
                        collapsed={collapsed}
                    />
                </ErrorBoundary>
                <Layout>
                    <ErrorBoundary>
                        <LayoutHeader
                            collapsed={collapsed}
                            setCollapsed={(val: boolean) => setCollapsed(val)}
                        />
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <Content
                            style={{background: token.mainBackground}}
                        >
                            <Outlet/>
                        </Content>
                    </ErrorBoundary>
                </Layout>

            </Layout>
        </>
    )
}
export default MainLayout

import {ApiTypeResponse} from "./types/ApiTypes";
import {apiCall, HttpMethods} from "./ApiManager";

export interface licensesApiParams {
    company: number,
    page?: number,
}
export interface licensesSettingsApiParams {
    page: number,
    search?: string,
    key?: string
}
export async function getLicensesList (params?: licensesApiParams): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/products/company_products/',params)
}

export async function createLicenses(body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/products/company_products/`, body)
}

export async function editLicenses (licenseId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/products/company_products/${licenseId}/`, body)
}

export async function deleteLicenses (licenseId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/products/company_products/${licenseId}/`)
}

export async function getLicenseSettings (licenseId: number, params?: licensesSettingsApiParams ): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, `/products/company_products/settings/?company_product=${licenseId}`,params)
    // return {data: licenseSettings}
}

export async function editFieldLicenseSettings (licenseId: number | null, fieldSettingId: number, body?: object ): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/products/company_products/settings/${fieldSettingId}/?company_product=${licenseId}`,body)
}

export async function deleteFieldLicenseSettings (licenseId: number, fieldSettingId: number ): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/products/company_products/settings/${fieldSettingId}/?company_product=${licenseId}`)
}

export async function createDemoDataOnCrm (licenseId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/products/create_demo_data/`, {company_product: licenseId})
}
export async function updateEndTimeLicense (licenseId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/products/update_end_time/`, {company_product: licenseId})
}

import React, {useCallback, useEffect} from "react";
import {LeadModalPropsTypes} from "./LeadModalTypes";
import {Button, Form, message, Modal} from "antd";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import doTask from "../../../logic/UiManager";
import {createLead, editLead} from "../../../logic/LeadsManager";
import TabsModalPartners from "../../partners/modal/mainModal/tabsModal/TabsModalPartners";
import {SwapOutlined} from "@ant-design/icons";

const LeadModal: React.FC<LeadModalPropsTypes> = (props) => {
    const [form] = Form.useForm()

    useEffect(() => {
        if (props.editableLead) {
            form.setFieldsValue({
                ...props.editableLead,
            })
        }
    },[props.editableLead])

    const handleCancelModal = useCallback(() => {
        form.resetFields()
        props.onCloseModal()
    },[props.visible])

    const callCreateOrEditLead = async (leadId: number | undefined, body: object) => {
        await doTask(async () => {
            const res = leadId ? await editLead(leadId, body) : await createLead(body)
            if (res) {
                message.success(`Лид успешно ${leadId ? 'отредактирован' : 'добавлен'}`)
                if (!leadId && res.data?.id) {
                    props.handleGetLeadById(res.data.id)
                    return
                }
            }
        })
    }

    const handleCreateOrEditLead = () => {
        form.validateFields().then(async (values) => {
            const body = {
                ...values,
                comment: values.comment?.length > 0  ?  values.comment : null,
                status: 'lead'
            }
            await callCreateOrEditLead(props.editableLead?.id,body)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleTranslationToPartners = useCallback((editableLeadId?: number) => {
        if (!editableLeadId) return
        props.handleTranslationToPartners && props.handleTranslationToPartners(editableLeadId)
        handleCancelModal()
    },[props.handleTranslationToPartners,handleCancelModal])

    return (
        <>
            <Modal
                width={1000}
                destroyOnClose={true}
                title={props.editableLead ? 'Редактировать лида' : 'Добавить лида'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    props.editableLead &&
                    <Button
                        onClick={() => {
                            handleTranslationToPartners(props.editableLead?.id)
                        }}
                        icon={<SwapOutlined />}
                    >
                        В клиенты
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                            await handleCreateOrEditLead()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <TabsModalPartners
                        formBasicInformation={form}
                        editablePartner={props.editableLead}
                    />
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default LeadModal

import React, {useCallback, useState} from 'react';
import {Helmet} from "react-helmet";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import {Box} from "@material-ui/core";
import ProductsTable from "../components/products/table/ProductsTable";
import Toolbar from "../components/UI/toolbar/Toolbar";
import ProductModal from "../components/products/modal/mainModal/ProductModal";
import {Product} from "../components/products/table/ProductTablePropsTypes";
import useAsyncEffect from "use-async-effect";
import {defaultFilters} from "../logic/types/ApiTypes";
import doTask from "../logic/UiManager";
import {deleteProduct, getProductsList} from "../logic/ProductsManager";
import {message} from "antd";
import useStateRef from 'react-usestateref'
import {calculationPageAfterActionFunction} from "../utils/pagination/calculationPageAfterActionFunction";

const ProductsPage: React.FC = () => {
    const [productsList,setProductsList,productsListRef] = useStateRef<Product[] | undefined>([])
    const [totalProducts,setTotalProducts] = useState<number>(0)
    const [page,setPage,pageRef] = useStateRef<number>(1)
    const [search,setSearch,searchRef] = useStateRef<string | undefined>('')
    const [loading,setLoading] = useState(false)
    const [visibleModalProducts,setVisibleModalProducts] = useState<boolean>(false)
    const [editableProduct,setEditableProduct] = useState<Product | null>(null)

    useAsyncEffect(async () => {
        await handleUpdateList(page)
    },[])

    const callGetProductsList = async (params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getProductsList(params)
            if (res?.data) {
                setProductsList(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalProducts(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateList = useCallback(async (page: number, search?: string | undefined) => {
        setPage(page)
        setSearch(search)
        const params: defaultFilters = {page}
        if (search) {
            params['search'] = search
        }
        await callGetProductsList(params)
    },[page,search])


    const handleOpenModalProduct = useCallback(() => {
        setVisibleModalProducts(true)
    },[visibleModalProducts])

    const handleCloseModalProduct = useCallback(async () => {
        setVisibleModalProducts(false)
        setEditableProduct(null)
        await handleUpdateList(page,search)
    },[page,search,visibleModalProducts])


    const handleEditProduct = useCallback((product: Product) => {
        if (!product) return
        setVisibleModalProducts(true)
        setEditableProduct(product)
    },[editableProduct,visibleModalProducts])

    const handleDeleteProduct = useCallback(async (productId: number) => {
        await doTask(async () => {
            if (!productId) return
            const res = await deleteProduct(productId)
            if (res) {
                message.success('Продукт успешно удален!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,productsListRef.current?.length)
                await handleUpdateList(pageFromUpdate,searchRef.current)
            }
        })
    },[pageRef,searchRef])

    return (
        <>
            <Helmet>
                <title>Продукты | QT-Админ</title>
            </Helmet>

            <Box style={{padding: '25px'}}>
                <ErrorBoundary>
                    <Toolbar
                        loading={false}
                        handleUpdate={async (searchKeyword?: string) => {
                            await handleUpdateList( searchKeyword && searchKeyword?.length > 0 ? 1 : pageRef.current, searchKeyword)
                        }}
                        placeholder={'Поиск продуктов (Название)'}
                        openModal={() => {
                            handleOpenModalProduct()
                        }}

                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ProductsTable
                        productsList={productsList}
                        loading={loading}
                        page={page}
                        totalProducts={totalProducts}
                        handleEdit={(product: Product) => {
                            handleEditProduct(product)
                        }}
                        handleDelete={async (productId: number) => {
                            await handleDeleteProduct(productId)
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ProductModal
                        visible={visibleModalProducts}
                        editableProduct={editableProduct}
                        onCloseModal={async () => {
                            await handleCloseModalProduct()
                        }}
                    />
                </ErrorBoundary>
            </Box>
        </>
    );
};

export default ProductsPage;

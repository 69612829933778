import React, {useCallback, useState} from "react";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import Toolbar from "../components/UI/toolbar/Toolbar"
import {Helmet} from "react-helmet"
import {Box} from "@material-ui/core";
import LeadsTable from "../components/leads/table/LeadsTable";
import {Lead} from "../components/leads/table/LeadsTableTypes";
import useAsyncEffect from "use-async-effect";
import doTask from "../logic/UiManager";
import {deleteLead, editLead, getLeadById, getLeadsList} from "../logic/LeadsManager";
import LeadModal from "../components/leads/modal/LeadModal";
import {message} from "antd";
import {defaultFilters} from "../logic/types/ApiTypes";
import useStateRef from 'react-usestateref'
import {calculationPageAfterActionFunction} from "../utils/pagination/calculationPageAfterActionFunction";

const LeadsPage: React.FC = () => {
    const [leadsList,setLeadsList,leadsListRef] = useStateRef<Lead[] | undefined>([])
    const [totalLeads,setTotalLeads] = useState<number>(0)
    const [page,setPage, pageRef] = useStateRef<number>(1)
    const [search,setSearch, searchRef] = useStateRef<string | undefined>('')
    const [loading,setLoading] = useState(false)
    const [visibleModalLead,setVisibleModalLead] = useState<boolean>(false)
    const [editableLead,setEditableLead] = useState<Lead | null>(null)


    useAsyncEffect(async () => {
        await handleUpdateList(page)
    },[])

    const callGetLeadsList = async (params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getLeadsList(params)
            if (res?.data) {
                setLeadsList(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalLeads(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleOpenModalLead = useCallback(() => {
        setVisibleModalLead(true)
    },[visibleModalLead])

    const handleCloseModalLead = useCallback(async () => {
        setVisibleModalLead(false)
        setEditableLead(null)
        await handleUpdateList(page,search)
    },[page,search,visibleModalLead])

    const handleUpdateList = useCallback(async (page: number, search?: string | undefined) => {
        setPage(page)
        setSearch(search)
        const params: defaultFilters = {page}
        if (search) {
            params['search'] = search
        }
        await callGetLeadsList(params)
    },[page,search])

    const handleGetLeadById = useCallback(async (leadId: number) => {
        if (!leadId) return
        await doTask(async () => {
            const res = await getLeadById(leadId)
            if (res.data) {
                setEditableLead(res.data)
            }
        })
    },[])

    const handleEditLead = useCallback((lead: Lead) => {
        if (!lead) return
        setVisibleModalLead(true)
        setEditableLead(lead)
    },[editableLead,visibleModalLead])

    const handleDeleteLead = useCallback(async (leadId: number) => {
        const hide = message.loading('Удаление лида',10000)
        await doTask(async () => {
            if (!leadId) return
            const res = await deleteLead(leadId)
            if (res) {
                message.success('Лид успешно удален!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,leadsListRef.current?.length)
                await handleUpdateList(pageFromUpdate,searchRef.current)
            }
        }).finally(() => {
            hide()
        })
    },[pageRef,searchRef])

    const handleTranslationToPartners = useCallback(async (leadId: number) => {
        if (!leadId) return
        await doTask(async () => {
            const res =  await editLead(leadId, {status: 'part'})
            if (res) {
                message.success(`Лид успешно переведен в клиента CRM`)
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,leadsListRef.current?.length)
                await handleUpdateList(pageFromUpdate,searchRef.current)
            }
        })
    },[pageRef,searchRef])

    return (
        <>
            <Helmet>
                <title>Лиды | QT-Админ</title>
            </Helmet>

            <Box style={{padding: '25px'}}>
                <ErrorBoundary>
                    <Toolbar
                        loading={false}
                        handleUpdate={async (searchKeyword?: string) => {
                            await handleUpdateList( searchKeyword && searchKeyword?.length > 0 ? 1 : pageRef.current, searchKeyword)
                        }}
                        placeholder={'Поиск лидов (Название компании, email, телефон, контактное лицо)'}
                        openModal={() => {
                            handleOpenModalLead()
                        }}

                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <LeadsTable
                        leadsList={leadsList}
                        loading={loading}
                        page={page}
                        totalLeads={totalLeads}
                        handleUpdateList={async (page: number) => {
                            await handleUpdateList(page,search)
                        }}
                        handleEdit={(lead: Lead) => {
                            handleEditLead(lead)
                        }}
                        handleDelete={async (leadId: number) => {
                            await handleDeleteLead(leadId)
                        }}
                     />
                </ErrorBoundary>

                <ErrorBoundary>
                    <LeadModal
                        visible={visibleModalLead}
                        editableLead={editableLead}
                        onCloseModal={async () => {
                            await handleCloseModalLead()
                        }}
                        handleGetLeadById={async (leadId: number) => {
                            await handleGetLeadById(leadId)
                        }}
                        handleTranslationToPartners={async (leadId: number) => {
                            await handleTranslationToPartners(leadId)
                        }}
                    />
                </ErrorBoundary>
            </Box>
        </>
    )
}

export default LeadsPage

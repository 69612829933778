import React, {useCallback, useMemo, useState} from "react";
import {TabsModalPartnersPropsTypes} from "./TabsModalPartnersTypes";
import LeadModalForm from "../../../../leads/modal/form/LeadModalForm";
import {Tabs} from "antd";
import Licenses from "./licenses/Licenses";

const TabsModalPartners: React.FC<TabsModalPartnersPropsTypes> = (props) => {
    const [activeTabKey,setActiveTabKey] = useState<string>('basicInformation')

    const tabs = useMemo(() => {
        return [
            {
                label: `Основаная информация`,
                children: <div style={{minHeight: '371px'}}>
                             <LeadModalForm form={props.formBasicInformation}/>
                          </div>,
                key: 'basicInformation'
            },
            {
                label: `Лицензии`,
                disabled: !props.editablePartner,
                children: <Licenses partnerId={props.editablePartner?.id}/>,
                key: 'licenses'
            }
        ]
    },[activeTabKey,props.editablePartner])

    const handleChangeActiveTabKey = useCallback((key: string) => {
        if (!key) return
        setActiveTabKey(key)
    },[activeTabKey])

    return (
        <>
            <Tabs
                destroyInactiveTabPane={true}
                onChange={(key: string) => handleChangeActiveTabKey(key)}
                activeKey={activeTabKey}
                type="card"
                items={tabs}
            />
        </>
    )
}

export default TabsModalPartners

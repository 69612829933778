import React, {useCallback, useState} from "react";
import {License, LicensesPropsTypes} from "./LicensesTypes";
import {Box} from "@material-ui/core";
import {Button, message, Row} from "antd";
import useAsyncEffect from "use-async-effect";
import doTask from "../../../../../../logic/UiManager";
import {
    editFieldLicenseSettings,
    getLicenseSettings,
    getLicensesList,
    licensesApiParams
} from "../../../../../../logic/LicensesManager";
import {PlusOutlined} from "@ant-design/icons";
import ErrorBoundary from "../../../../../errorBoundary/ErrorBoundary";
import LicensesList from "./licensesList/LicensesList";
import LicenseModal from "./modal/LicenseModal";
import LicensesSettings from "./licensesSettings/LicensesSettings";
import {FieldSetting} from "./licensesSettings/form/LicensesSettingsFormTypes";
import LicensesSettingsUniversalModal from "./licensesSettings/modal/LicensesSettingsUniversalModal";
import dayjs from "dayjs";


const Licenses: React.FC<LicensesPropsTypes> = (props) => {
    const [licensesList, setLicensesList] = useState<License[]>([])
    const [totalLicenses, setTotalLicenses] = useState<number>(0)
    const [loading,setLoading] = useState<boolean>(false)
    const [page,setPage] = useState<number>(1)
    const [visibleModalLicense,setVisibleModalLicense] = useState<boolean>(false)
    const [customizableLicenseId, setCustomizableLicenseId] = useState<number | null>(null)
    const [licenseSettingsList, setLicenseSettingsList] = useState<FieldSetting[]>([])
    const [visibleLicenseSettingsModal,setVisibleLicenseSettingsModal] = useState<boolean>(false)

    useAsyncEffect(async () => {
        await handleUpdateLicensesList(page)
    },[])


    const callGetLicensesList = async (params?: licensesApiParams) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getLicensesList(params)
            if (res?.data) {
                setLicensesList(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalLicenses(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateLicensesList = useCallback(async (page: number) => {
        setPage(page)
        const params = {page,company: props.partnerId} as licensesApiParams
        await callGetLicensesList(params)
    },[page,props.partnerId])

    const handleOpenModalLicense = useCallback(() => {
        setVisibleModalLicense(true)
    },[visibleModalLicense])

    const handleCloseModalLicense = useCallback(async () => {
        setVisibleModalLicense(false)
        await handleUpdateLicensesList(page)
    },[page,visibleModalLicense])


    const handleCustomizableLicense = useCallback((customizableLicenseId: number) => {
        if (!customizableLicenseId) return
        setCustomizableLicenseId(customizableLicenseId)
        setLicensesList([])
    },[customizableLicenseId])

    const resetLicenseId = useCallback(async () => {
        setCustomizableLicenseId(null)
        await handleUpdateLicensesList(page)
    },[customizableLicenseId])


    const handleTransitionProductLicense = useCallback(async (licenseId: number) => {
        if (!licenseId) return
        await doTask(async () => {
            const hide = message.loading('Получение настроек', 1000)

            /*TODO: tokenKey and baseUrlKey вынести в ENV*/
            const tokenKey = 'PRODUCT_ADMIN_TOKEN'
            const baseUrlKey = 'BASE_URL'

            const tokenAndBaseUrlMap = new Map()
            const res = await getLicenseSettings(licenseId)
            if (!res.data) return

            res.data.forEach((filed: FieldSetting) => {
                if (filed.key === tokenKey && filed.value) {
                    tokenAndBaseUrlMap.set(tokenKey, filed.value)
                }
                if (filed.key === baseUrlKey && filed.value) {
                    tokenAndBaseUrlMap.set(baseUrlKey, filed.value)
                }
            })

            if (tokenAndBaseUrlMap.has(tokenKey) && tokenAndBaseUrlMap.has(baseUrlKey)) {
                const baseUrl = tokenAndBaseUrlMap.get(baseUrlKey)
                const token = tokenAndBaseUrlMap.get(tokenKey)
                //window.open(`${baseUrl}continuationAuthorization/?${token}`, '_blank');
                window.open(`${baseUrl}continuationAuthorization/?${token}`, '_blank');
            } else {
                message.warning('Токен или основной адрес не задан')
            }
            hide()
        })
    },[props.partnerId])


    const handleTerminateLicense = useCallback(async (licenseId: number) => {
        if (!licenseId) return
        const hide = message.loading('Прекращение действия лицензии', 1000)
        await doTask(async () => {
            const keyEndWorkDateTime = 'END_WORK_DATETIME'
            const res = await getLicenseSettings(licenseId, {page: 1, key: keyEndWorkDateTime})
            const sittingEndWorkDateTime = res?.data[0]
            if (sittingEndWorkDateTime?.id) {
                const body = {
                    value: String(dayjs().subtract(1,'day').format('YYYY-MM-DD'))
                }
                const res =  await editFieldLicenseSettings(licenseId, sittingEndWorkDateTime.id, body)
                if (res) {
                    message.success('Действие лицензии успешно прекращено')
                    await handleUpdateLicensesList(page)
                }
            }
        }).finally(() => {
            hide()
        })
    },[props.partnerId,page])


    const handleEditPeriodLicense = useCallback(async (customizableLicenseId: number) => {
        if (!customizableLicenseId) return
        await doTask(async () => {
            setVisibleLicenseSettingsModal(true)
            setCustomizableLicenseId(customizableLicenseId)

            const res = await getLicenseSettings(customizableLicenseId)
            if (res?.data) {
                setLicenseSettingsList(res.data)
            }
        })
    },[setLicenseSettingsList,setVisibleLicenseSettingsModal,customizableLicenseId])

    const handleCloseModalEditPeriodLicense = useCallback(async () => {
        setVisibleLicenseSettingsModal(false)
        setCustomizableLicenseId(null)
        setLicenseSettingsList([])
        await handleUpdateLicensesList(page)
    },[setVisibleLicenseSettingsModal])

    return (
        <>
            <Box style={{minHeight: '371px', padding: '0 5px 5px 5px'}}>

                {customizableLicenseId ?
                    <ErrorBoundary>
                        <LicensesSettings
                            licenseId={customizableLicenseId}
                            goToGeneralListLicenses={async () => {
                                await resetLicenseId()
                            }}
                        />
                    </ErrorBoundary> :
                <>
                    <Row justify={'end'}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={"middle"}
                            onClick={() => {
                                handleOpenModalLicense()
                            }}
                        >Добавить</Button>
                    </Row>
                    <ErrorBoundary>
                        <LicensesList
                            loading={loading}
                            licensesList={licensesList}
                            totalLicenses={totalLicenses}
                            handleTransitionProductLicense={async (licenseId: number) => {
                                await handleTransitionProductLicense(licenseId)
                            }}
                            page={page}
                            handleCustomizableLicense={(licenseId: number) => {
                                handleCustomizableLicense(licenseId)
                            }}
                            handleTerminateLicense={async (licenseId: number) => {
                                await handleTerminateLicense(licenseId)
                            }}
                            handleEditPeriodLicense={async (licenseId: number) => {
                                await handleEditPeriodLicense(licenseId)
                            }}
                        />
                    </ErrorBoundary>
                </> }

                <ErrorBoundary>
                    <LicenseModal
                        visible={visibleModalLicense}
                        partnerId={props.partnerId}
                        onCloseModal={async () => {
                            await handleCloseModalLicense()
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <LicensesSettingsUniversalModal
                        visible={visibleLicenseSettingsModal}
                        loading={loading}
                        customizableLicenseId={customizableLicenseId}
                        settingsList={licenseSettingsList}
                        editableKeys={['END_WORK_DATETIME','START_WORK_DATETIME']}
                        onCloseModal={async () => {
                            await handleCloseModalEditPeriodLicense()
                        }}
                     />
                </ErrorBoundary>
            </Box>
        </>
    )
}

export default Licenses

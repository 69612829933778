import React from "react";
import {DropdownPropsTypes} from "./DropdownTypes";
import {Menu, Popconfirm} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const DropdownMenu: React.FC<DropdownPropsTypes> = (props) => {
    return (
        <>
            <Menu style={{width: 200}}>
                <Menu.Item
                    icon={<EditOutlined/>}
                    onClick={() => {
                        props.handleEdit(props.recordId)
                    }}
                >
                    Редактировать
                </Menu.Item>
                {!props.hideDeletion &&
                <Popconfirm
                    title="Вы действительно хотите удалить запись?"
                    onConfirm={async () => {
                        props.handelDelete(props.recordId)
                    }}
                    okText="Да"
                    cancelText="Нет"
                >
                    <Menu.Item icon={<DeleteOutlined/>}>Удалить</Menu.Item>
                </Popconfirm>}
            </Menu>
        </>
    )
}

export default DropdownMenu

import React from 'react';
import './App.css';
import {ConfigProvider} from "antd";
import localeRu from "antd/locale/ru_RU"
import themeCustom from "./theme/theme";
import RouterPages from "./router/RouterPages";
import GlobalStyles from "./globalStyles/GlobalStyles";
import './globalStyles/global.css'
import dayjs from "dayjs";
dayjs.locale('ru')

function App() {
  return (
    <ConfigProvider theme={themeCustom}>
        <ConfigProvider locale={localeRu}>
            <GlobalStyles/>
            <RouterPages/>
        </ConfigProvider>
    </ConfigProvider>
  )
}

export default App;

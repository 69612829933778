import React from "react";
import {ServerModalFormPropsTypes} from "./ServerModalFormTypes";
import {Col, Form, Input, Row} from "antd";

const ServerModalForm: React.FC<ServerModalFormPropsTypes> = (props) => {
    return (
        <>
            <Form form={props.form} layout={'vertical'}>
                <Row gutter={20}>
                    <Col lg={24} xs={24}>
                        <Form.Item
                            label={"Ip адрес"}
                            name={"host"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Ip адрес"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ServerModalForm

import React, {useCallback, useEffect} from "react";
import {Button, Form, message, Modal} from "antd";
import doTask from "../../../../logic/UiManager";
import {createPartner, editPartner} from "../../../../logic/PartnersManager";
import {PartnerModalPropsTypes} from "./PartnerModalTypes";
import ErrorBoundary from "../../../errorBoundary/ErrorBoundary";
import TabsModalPartners from "./tabsModal/TabsModalPartners";

const PartnerModal: React.FC<PartnerModalPropsTypes> = (props) => {

    const [formBasicInformation] = Form.useForm()

    useEffect(() => {
        if (props.editablePartner) {
            formBasicInformation.setFieldsValue({
                ...props.editablePartner,
            })
        }
    },[props.editablePartner])

    const handleCancelModal = useCallback(() => {
        formBasicInformation.resetFields()
        props.onCloseModal()
    },[props.visible])

    const callCreateOrEditPartner = async (leadId: number | undefined, body: object) => {
        await doTask(async () => {
            const res = leadId ? await editPartner(leadId, body) : await createPartner(body)
            if (res) {
                message.success(`Партнер успешно ${leadId ? 'отредактирован' : 'добавлен'}`)
                if (!leadId && res.data?.id) {
                    props.handleGetPartnerById(res.data.id)
                    return
                }
            }
        })
    }

    const handleCreateOrEditPartner = () => {
        formBasicInformation.validateFields().then(async (values) => {
            const body = {
                ...values,
                comment: values.comment?.length > 0  ?  values.comment : null,
                status: 'part'
            }
            await callCreateOrEditPartner(props.editablePartner?.id,body)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal
                width={1000}
                destroyOnClose={true}
                title={props.editablePartner ? 'Редактировать партнера' : 'Добавить партнера'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                            await handleCreateOrEditPartner()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <TabsModalPartners
                        formBasicInformation={formBasicInformation}
                        editablePartner={props.editablePartner}
                    />
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default PartnerModal

import React, {useCallback} from "react";
import {LicensesSettingsUniversalModalPropsTypes} from "./LicensesSettingsUniversalModalTypes";
import ErrorBoundary from "../../../../../../../errorBoundary/ErrorBoundary";
import LicensesSettingsForm from "../form/LicensesSettingsForm";
import {message, Modal, Spin} from "antd";
import doTask from "../../../../../../../../logic/UiManager";
import {editFieldLicenseSettings} from "../../../../../../../../logic/LicensesManager";


const LicensesSettingsUniversalModal: React.FC<LicensesSettingsUniversalModalPropsTypes> = (props) => {

    const handleCancelModal = useCallback(() => {
        props.onCloseModal()
    },[])

    const handleEditFieldLicenseSetting = useCallback(async (fieldSettingId: number,value: any) => {
        if (!fieldSettingId) return
        await doTask(async () => {
            const body = {
                value: String(value)
            }
            const res = await editFieldLicenseSettings(props.customizableLicenseId, fieldSettingId, body)
            if (res) {
                message.success(`Поле успешно отредактировано`)
            }
        })
    },[props.customizableLicenseId])

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={'Редактировать лицензию'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[]}
            >
                <Spin spinning={props.loading}>
                    <ErrorBoundary>
                        <LicensesSettingsForm
                            licenseSettingsList={props.settingsList}
                            editableKeys={props.editableKeys}
                            handleEditFieldLicenseSetting={async (fieldSettingId: number, value: any) => {
                                await handleEditFieldLicenseSetting(fieldSettingId, value)
                            }}
                        />
                    </ErrorBoundary>
                </Spin>
            </Modal>
        </>
    )
}

export default LicensesSettingsUniversalModal

import React, {useCallback, useState} from "react";
import {LicensesSettingsPropsTypes} from "./LicensesSettingsTypes";
import {Box} from "@material-ui/core";
import {Breadcrumb, Form, message, Row, Spin} from "antd";
import {
    deleteFieldLicenseSettings,
    editFieldLicenseSettings,
    getLicenseSettings
} from "../../../../../../../logic/LicensesManager";
import doTask from "../../../../../../../logic/UiManager";
import useAsyncEffect from "use-async-effect";
import ErrorBoundary from "../../../../../../errorBoundary/ErrorBoundary";
import {FieldSetting} from "./form/LicensesSettingsFormTypes";
import EditableTableProductsSettings
    from "../../../../../../products/productsSettings/editableTable/EditableTableProductsSettings";
import {defaultFilters} from "../../../../../../../logic/types/ApiTypes";
import useStateRef from 'react-usestateref'
import {
    calculationPageAfterActionFunction
} from "../../../../../../../utils/pagination/calculationPageAfterActionFunction";
import moment from "moment";
import dayjs from "dayjs";

const LicensesSettings: React.FC<LicensesSettingsPropsTypes> = (props) => {
    const [form] = Form.useForm()
    const [page,setPage,pageRef] = useStateRef(1)
    const [totalLicenseSettings,setTotalLicenseSettings] = useState<number>(0)
    const [licenseSettingsList,setLicenseSettingsList,licenseSettingsListRef] = useStateRef<FieldSetting[]>([])
    const [loading,setLoading] = useState<boolean>(false)

    useAsyncEffect(async () => {
        if (props.licenseId) {
            await handleUpdateLicenseSettingsList(props.licenseId, page)
        }
    },[props.licenseId])

    const callGetLicenseSettingsList = async (licenseId: number, params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getLicenseSettings(licenseId,params)
            if (res?.data) {
                setLicenseSettingsList(res.data as  FieldSetting[])
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalLicenseSettings(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateLicenseSettingsList = useCallback(async (licenseId: number,page: number) => {
        if (!licenseId) return
        setPage(page)
        const params: defaultFilters = {page}
        await callGetLicenseSettingsList(licenseId, params)
    },[props.licenseId])

    const callEditFieldLicenseSetting = async (licenseId: number, fieldSettingId: number, body?: object) => {
        await doTask(async () => {
            const res =  await editFieldLicenseSettings(licenseId, fieldSettingId, body)
            if (res) {
                message.success(`Поле успешно отредактировано`)
                await handleUpdateLicenseSettingsList(props.licenseId, page)
            }
        })
    }

    const handleEditFieldLicenseSetting = useCallback((fieldSettingId: number,recordType: string) => {
        if (!fieldSettingId) return
        form.validateFields().then(async (values) => {
            console.log(values)
            const body = {
                ...values,
                value: recordType === 'datetime' ? dayjs(values.value).format('YYYY-MM-DD') : String(values.value)
            }
            await callEditFieldLicenseSetting(props.licenseId,fieldSettingId, body)
        }).catch((err) => {
            console.log(err)
        })
    },[page, props.licenseId])

    const handleDeleteFieldLicenseSetting = useCallback(async (fieldSettingId: number,licenseId: number,) => {
        await doTask(async () => {
            if (!fieldSettingId) return
            const res = await deleteFieldLicenseSettings(licenseId,fieldSettingId)
            if (res) {
                message.success('Поле успешно удалено!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,licenseSettingsListRef.current?.length)
                await handleUpdateLicenseSettingsList(props.licenseId, pageFromUpdate)
            }
        })
    },[pageRef, props.licenseId])

    const goToGeneralListLicenses = useCallback(() => {
        props.goToGeneralListLicenses && props.goToGeneralListLicenses()
    },[props.goToGeneralListLicenses])

    return (
        <>
            <Box>
                <Row justify={'start'}>
                    <Breadcrumb
                        items={[
                            {
                                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                                title: <a onClick={() => goToGeneralListLicenses()}>Лицензии</a>
                            },
                            {
                                title: props.licenseId,
                            },
                            {
                                title: 'Настройки',
                            }
                        ]}
                    />
                </Row>
                <Spin spinning={loading}>
                    <Box style={{marginTop: '25px'}}>
                        <ErrorBoundary>
                           {/* <LicensesSettingsForm licenseSettingsList={licenseSettings} />*/}
                            <EditableTableProductsSettings
                                form={form}
                                loading={loading}
                                page={page}
                                productSettingsList={licenseSettingsList}
                                total={totalLicenseSettings}
                                hideKeyColumn={true}
                                hideSettingsKeysList={['ENDLESS_WORK']}
                                handleUpdateList={async (page: number) => {
                                    await handleUpdateLicenseSettingsList(props.licenseId, page)
                                }}
                                handleEditFieldSetting={async (fieldSettingId: number,recordType: string) => {
                                    await handleEditFieldLicenseSetting(fieldSettingId,recordType)
                                }}
                                handleDelete={async (fieldId: number) => {
                                    await handleDeleteFieldLicenseSetting(fieldId, props.licenseId)
                                }}
                            />
                        </ErrorBoundary>
                    </Box>
                </Spin>
            </Box>
        </>
    )
}

export default LicensesSettings

import {UserModalFormPropsTypes} from "./UserModalFormTypes";
import React from "react";
import {Form, Input, Row, Col, Switch} from "antd";

const UserModalForm: React.FC<UserModalFormPropsTypes> = (props) => {
    return (
        <>
            <Form form={props.form} layout={'vertical'}>
                <Row gutter={20}>
                    <Col lg={20} xs={24}>
                        <Form.Item
                            label={"Telegram"}
                            name={"telegram"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Telegram"}/>
                        </Form.Item>
                    </Col>
                    <Col lg={4} xs={4}>
                        <Form.Item label="Активен">
                            <Form.Item name="active">
                                <Switch defaultChecked={true} />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default UserModalForm

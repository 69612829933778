import {apiCall, HttpMethods} from "./ApiManager";
import {ApiTypeResponse, defaultFilters} from "./types/ApiTypes";

export async function getUsersList (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/users/users/',params)
}

export async function createUser (body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/users/new_user`, body)
}

export async function editUser (userId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/users/users/${userId}/`, body)
}

export async function deleteUser (userId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/users/users/${userId}/`)
}

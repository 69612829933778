const themeCustom = {
    token:
        {
            colorPrimary: '#0b7b7f',
            colorSecondary: '#ffffff',
            mainBackground: '#FFFFFF',
            textColorWhite: '#FFFFFF',
            secondaryBg: '#eeeeee'
        },
}

export default themeCustom;

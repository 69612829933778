import React, {useCallback, useMemo, useState} from "react";
import {TabsModalProductPropsTypes} from "./TabsModalProductTypes";
import {Tabs} from "antd";
import BasicInformationProductForm from "./basicInformationProduct/BasicInformationProductForm";
import ProductsSettings from "../../../productsSettings/ProductsSettings";
import Servers from "./servers/Servers";


const TabsModalProduct: React.FC<TabsModalProductPropsTypes> = (props) => {
    const [activeTabKey,setActiveTabKey] = useState<string>('basicInformation')

    const tabs = useMemo(() => {
        return [
            {
                label: `Основаная информация`,
                children: <div style={{minHeight: '451px'}}>
                    <BasicInformationProductForm form={props.form}/>
                </div>,
                key: 'basicInformation'
            },
            {
                label: `Настройки`,
                disabled: !props.editableProduct,
                children: <ProductsSettings editableProduct={props.editableProduct}/>,
                key: 'settings'
            },
            {
                label: `Сервера`,
                disabled: !props.editableProduct,
                children: <Servers productId={props.editableProduct?.id}/>,
                key: 'servers'
            }
        ]
    },[activeTabKey])

    const handleChangeActiveTabKey = useCallback((key: string) => {
        if (!key) return
        setActiveTabKey(key)
    },[activeTabKey])

    return (
        <>
            <Tabs
                destroyInactiveTabPane={true}
                onChange={(key: string) => handleChangeActiveTabKey(key)}
                activeKey={activeTabKey}
                type="card"
                items={tabs}
            />
        </>
    )
}

export default TabsModalProduct

import React, {useCallback, useState} from "react";
import {Helmet} from "react-helmet";
import {Box} from "@material-ui/core";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import Toolbar from "../components/UI/toolbar/Toolbar";
import {deleteUser, getUsersList} from "../logic/UsersManager";
import useAsyncEffect from "use-async-effect";
import UsersTable from "../components/users/table/UsersTable";
import {User} from "../components/users/table/UsersTableTypes";
import UserModal from "../components/users/modal/UserModal";
import {message} from "antd";
import doTask from "../logic/UiManager";
import {defaultFilters} from "../logic/types/ApiTypes";
import useStateRef from 'react-usestateref'
import {calculationPageAfterActionFunction} from "../utils/pagination/calculationPageAfterActionFunction";

const UsersPage: React.FC = () => {
    const [loading,setLoading] = useState<boolean>(false)
    const [users,setUsers, usersRef] = useStateRef<User[]>()
    const [totalUsers,setTotalUsers] = useState<number>(0)
    const [search,setSearch,searchRef] = useStateRef<string | undefined>('')
    const [page,setPage,pageRef] = useStateRef<number>(1)
    const [visibleModalUser, setVisibleModalUser] = useState<boolean>(false)
    const [editableUser,setEditableUser] = useState<User | null>(null)

    useAsyncEffect(async () => {
        await handleUpdateList(page)
    },[])

    const callGetUserList = async (params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getUsersList(params)
            if (res?.data) {
                setUsers(res.data)
            }
            if (res.count !== null && res.count !== undefined) {
                setTotalUsers(res?.count)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateList = useCallback(async (page: number, search?: string | undefined) => {
        setPage(page)
        setSearch(search)
        const params: defaultFilters = {page,search}
        await callGetUserList(params)
    },[page,search])

    const handleCloseModalUser = useCallback(async () => {
        setVisibleModalUser(false)
        setEditableUser(null)
        await handleUpdateList(page,search)
    },[visibleModalUser,page,search])

    const handleOpenModalUser = useCallback(() => {
        setVisibleModalUser(true)
    },[visibleModalUser])

    const handleEditUser = useCallback((user: User) => {
        if (!user) return
        setVisibleModalUser(true)
        setEditableUser(user)
    },[visibleModalUser,editableUser])


    const handleDeleteUser = useCallback(async (userId: number) => {
        await doTask(async () => {
            if (!userId) return
            const res = await deleteUser(userId)
            if (res) {
                message.success('Пользователь успешно удален!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,usersRef.current?.length)
                await handleUpdateList(pageFromUpdate,searchRef.current)
            }
        })
    },[pageRef,searchRef])

    return (
        <>
            <Helmet>
                <title>Пользователи | QT-Админ</title>
            </Helmet>

            <Box style={{padding: '25px'}}>
                <ErrorBoundary>
                    <Toolbar
                        loading={loading}
                        handleUpdate={async (searchKeyword?: string) => {
                            await handleUpdateList( searchKeyword && searchKeyword?.length > 0 ? 1 : pageRef.current, searchKeyword)
                        }}
                        placeholder={'Поиск пользователей (никнейм)'}
                        openModal={() => {
                            handleOpenModalUser()
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <UsersTable
                        usersList={users}
                        loading={loading}
                        totalUsers={totalUsers}
                        page={page}
                        handleUpdateList={async (page: number) => {
                            await handleUpdateList(page,search)
                        }}
                        handleEditUser={(user: User) => {
                            handleEditUser(user)
                        }}
                        handleDeleteUser={async (userId: number) => {
                            await handleDeleteUser(userId)
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <UserModal
                        visible={visibleModalUser}
                        editableUser={editableUser}
                        onCloseModal={async () => {
                            await handleCloseModalUser()
                        }}
                    />
                </ErrorBoundary>
            </Box>
        </>
    )
}

export default UsersPage

import React, { Component, ErrorInfo, ReactNode } from "react";
import {Alert} from "antd";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error;
    errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: {name: "", message: ""},
        errorInfo: {componentStack: ''}
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error,
            errorInfo
        })
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Alert
                    style={{width: '100%'}}
                    message="Ошибка отрисовки компонента..."
                    description={this.state.error && this.state.error.toString()}
                    type="error"
                    showIcon
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import React, {useCallback} from 'react';
import {Product, ProductTablePropsTypes} from "./ProductTablePropsTypes";
import {Space, Table, TableColumnsType, TablePaginationConfig} from "antd";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import DropdownActions from "../../dropdown/dropdownMenu/DropdownActions";
import {tablePaginationConfig} from "../../users/table/UsersTableTypes";
import {setPaginationFunction} from "../../../utils/pagination/setPaginationFunction";

const ProductsTable: React.FC<ProductTablePropsTypes>= (props) => {

    const columnsTable: TableColumnsType<Product> = [
        {
            title: " ",
            align: "center",
            width: '60px',
            key: 'dropdown',
            render: (product: Product) => (
                <Space size="middle">
                    <ErrorBoundary>
                        <DropdownActions
                            handleEdit={() => {
                               props.handleEdit && props.handleEdit(product)
                            }}
                            handelDelete={(productId: number) => {
                                props.handleDelete && props.handleDelete(productId)
                            }}
                        />
                    </ErrorBoundary>
                </Space>
            ),
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Кодовое название',
            dataIndex: 'codename',
            key: 'codename',
            width: '40%',
            ellipsis: {
                showTitle: false
            },
        }
    ]

    const handleChangePaginationTable = useCallback((paginationConfig: tablePaginationConfig) => {
        props.handleUpdateList && props.handleUpdateList(paginationConfig.current)
    },[props.handleUpdateList])

    return (
        <>
            <Table
                columns={columnsTable}
                dataSource={props.productsList}
                scroll={{x: 'max-content'}}
                loading={props.loading}
                onChange={(pagination: TablePaginationConfig) => handleChangePaginationTable(pagination as tablePaginationConfig) }
                pagination={setPaginationFunction(props.totalProducts, props.page)}
                onRow={(product) => {
                    return {
                        onDoubleClick: () => {
                            props.handleEdit(product)
                        },
                    };
                }}
                size="small"
            />
        </>
    );
};

export default ProductsTable

import React, {useCallback, useEffect, useState} from "react";
import {LicenseItemPropsTypes} from "./LicenseItemTypes";
import {Button, Card, Col, Row, Tag, Tooltip} from "antd";
import classNames from "classnames";
import cl from './licenseItem.module.css'
import {ArrowRightOutlined, CalendarOutlined, StopOutlined} from "@ant-design/icons";
import {License} from "../../LicensesTypes";
import moment from "moment";

const LicenseItem: React.FC<LicenseItemPropsTypes> = (props) => {
    const [licenseExpired,setExpired] = useState<boolean>(false)

    useEffect(() => {
        setExpired(!props.license?.license_active)
    },[props.license])

    const handleGetLicenseStartEndDate = useCallback((license?: License) => {
        const start = license?.date_start ? moment(license.date_start.replace('Z', '+')).format('DD.MM.YYYY') : 'Не задано'
        const end = license?.date_end ? moment(license.date_end.replace('Z', '+')).format('DD.MM.YYYY') : 'Не задано'
        return `${start} - ${end}`
    },[props.license])

    return (
        <>
            <Card
                className={classNames(cl.licenceItem, licenseExpired && cl.expired)}
                hoverable={true}
                onClick={() => {
                    props.handleCustomizableLicense && props.handleCustomizableLicense(props.license?.id)
                }}
            >
                <Row>
                    <Col lg={12} md={12} xl={12} xs={12} className={classNames(cl.licenceItemTitle)}>
                        <Row gutter={10}>
                            <Col>
                                {props.license?.product_name}
                            </Col>
                            <Col>
                                <Tag color="default">
                                    {licenseExpired ? 'Действие прекращено' : handleGetLicenseStartEndDate(props.license)}
                                </Tag>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} md={12} xl={12} xs={12}>
                        {!licenseExpired &&
                        <Row gutter={10} justify={'end'}>
                            <Col>
                                <Tooltip title={'Прекратить лицензию'}>
                                    <Button
                                        danger={true}
                                        type={"primary"}
                                        icon={<StopOutlined/>}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            props.handleTerminateLicense && props.handleTerminateLicense(props.license?.id)
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip title={'Редактировать срок действия'}>
                                    <Button
                                        type="primary"
                                        ghost={true}
                                        icon={<CalendarOutlined />}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            props.handleEditPeriodLicense && props.handleEditPeriodLicense(props.license?.id)
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip title={'Перейти в CRM компании'}>
                                    <Button
                                        type={'primary'}
                                        icon={<ArrowRightOutlined />}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            props.handleTransitionProductLicense(props.license?.id)
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default LicenseItem

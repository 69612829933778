import React, {useCallback, useEffect, useState} from 'react';
import {ServersModalPropsTypes} from "./ServersModalTypes";
import {Button, Form, message, Modal, Spin} from "antd";
import ErrorBoundary from "../../../../../../errorBoundary/ErrorBoundary";
import ServersTabsModalForm from "./form/ServersTabsModalForm";
import doTask from "../../../../../../../logic/UiManager";
import {createProductServer, editProductServer} from "../../../../../../../logic/ProductsManager";
import {Server} from "../../../../../../servers/table/ServersTableTypes";
import {getServers} from "../../../../../../../logic/ServersManager";
import useAsyncEffect from "use-async-effect";

const ServersModal: React.FC<ServersModalPropsTypes> = (props) => {
    const [form] = Form.useForm()
    const [serversList, setServersList] = useState<Server[] | undefined>()
    const [loading,setLoading] = useState<boolean>(false)

    useAsyncEffect(async () => {
        if (props.visible) {
            await handleGetServersList()
        }
    },[props.visible])

    useEffect(() => {
        if (props.editableServer) {
            form.setFieldsValue({
                ...props.editableServer,
            })
        }
    },[props.editableServer])

    const handleCancelModal = useCallback(() => {
        form.resetFields()
        props.onCloseModal()
    },[props.visible])

    const handleCreateOrEditProductServer = useCallback(async () => {
        form.validateFields().then(async (values) => {
            const body = {
                ...values,
                product: props.productId,
            }
            await callCreateOrEditProductServer({
                editableServerId: props.editableServer?.id,
                productId: props.productId,
                body: body
            })
        }).catch((err) => {
            console.log(err)
        })
    }, [])


    const callCreateOrEditProductServer = async ({
                                                     editableServerId,
                                                     productId,
                                                     body
                                                 }: { editableServerId?: number, productId?: number | undefined, body: object }) => {
        if (!productId) return
        await doTask(async () => {
            const res = editableServerId ? await editProductServer(editableServerId,productId,body)
                                                : await createProductServer(productId, body)
            if (res) {
                message.success(`Сервер успешно ${editableServerId ? 'отредактирован' : 'добавлен'}`)
                handleCancelModal()
            }
        })
    }

    const callGetServersList = async () => {
        await doTask(async () => {
            const res = await getServers()
            if (res?.data) {
                setServersList(res.data)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleGetServersList = useCallback(async () => {
        await callGetServersList()
    },[props.visible])

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={props.editableServer ?  'Редактировать сервер' : 'Добавить сервер'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        onClick={async () => {
                            await handleCreateOrEditProductServer()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <Spin spinning={loading}>
                    <ErrorBoundary>
                        <ServersTabsModalForm
                            form={form}
                            serversList={serversList}
                        />
                    </ErrorBoundary>
                </Spin>
            </Modal>
        </>
    );
};

export default ServersModal;

import React from 'react';
import classes from "./logo.module.css";
import {Link as RouterLink} from "react-router-dom";
import classNames from 'classnames';
import {LogoPropsTypes} from "./LogoTypes";
const Logo : React.FC<LogoPropsTypes> = (props) => {
    return (
        <RouterLink
            to={"/leads"}
            className={classes.LogoWrapper}
        >
            <img
                className={classNames(classes.LogoSmall, classes.active)}
                alt="КвикТранс"
                src={ props.collapsed
                    ? "../static/svg/logo/qt_small_green.svg"
                    : "../static/svg/logo/qt_small_second_green.svg"
                }
            />

            <img
                className={classNames(classes.LogoCompanyName, props.collapsed ? classes.inactive : classes.active)}
                alt="КвикТранс"
                src={"../static/svg/logo/qt_title_green.svg"}
            />
        </RouterLink>
    );
};

export default Logo;

import {ApiTypeResponse, defaultFilters} from "./types/ApiTypes";
import {apiCall, HttpMethods} from "./ApiManager";

export async function getLeadsList (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/companies/leads/',params)
}

export async function getLeadById (leadId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, `/companies/leads/${leadId}`)
}

export async function createLead (body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/companies/leads/`, body)
}

export async function editLead (leadId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/companies/leads/${leadId}/`, body)
}

export async function deleteLead (leadId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/companies/leads/${leadId}/`)
}

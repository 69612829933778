import React, {useCallback, useEffect} from "react";
import {UserModalPropsTypes} from "./UserModalTypes";
import {Button, Form, message, Modal} from "antd";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import UserModalForm from "./form/UserModalForm";
import doTask from "../../../logic/UiManager";
import {createUser, editUser} from "../../../logic/UsersManager";

const UserModal: React.FC<UserModalPropsTypes> = (props) => {
    const [form] = Form.useForm()

    useEffect(() => {
        if (props.editableUser) {
            form.setFieldsValue({
                ...props.editableUser,
            })
        }
    },[props.editableUser])

    const handleCancelModal = useCallback(() => {
        form.resetFields()
        props.onCloseModal()
    },[props.visible])

    const callCreateOrEditUser = async (userId: number | undefined, body: object) => {
        await doTask(async () => {
            const res = userId ? await editUser(userId, body) : await createUser(body)
            if (res) {
                message.success(`Пользователь успешно ${userId ? 'отредактирован' : 'добавлен'}`)
                handleCancelModal()
            }
        })
    }

    const handleCreateOrEditUser = () => {
        form.validateFields().then(async (values) => {
            const body = {
                ...values
            }
            await callCreateOrEditUser(props.editableUser?.id,body)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={props.editableUser ? 'Редактировать пользователя' : 'Добавить пользователя'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                            await handleCreateOrEditUser()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <UserModalForm form={form}/>
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default UserModal

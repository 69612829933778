import React, {useCallback} from "react";
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const NotFoundPage: React.FC<{}> = () => {
    const navigate = useNavigate();

    const navigateToMainPage = useCallback(() => {
        navigate('/leads')
    },[navigate])

    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Извините, такой страницы не существует"
                extra={<Button onClick={() => navigateToMainPage()} type="primary">На главную</Button>}
            />
        </>
    )
}

export default NotFoundPage

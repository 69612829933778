import {ApiTypeResponse, defaultFilters} from "./types/ApiTypes";
import {apiCall, HttpMethods} from "./ApiManager";

export async function getServers (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/servers/servers/',params)
}

export async function getServersList (params?: defaultFilters): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.GET, '/servers/servers/',params)
}

export async function createServer (body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.POST, `/servers/servers/`, body)
}

export async function editServer (serverId: number, body: object): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.PATCH, `/servers/servers/${serverId}/`, body)
}

export async function deleteServer (serverId: number): Promise<ApiTypeResponse> {
    return await apiCall(HttpMethods.DELETE, `/servers/servers/${serverId}/`)
}

import React from "react";
import {ProductsSettingsModalTypes} from "./ProductsSettingsModalTypes";
import {Button, message, Modal} from "antd";
import ErrorBoundary from "../../../errorBoundary/ErrorBoundary";
import ProductsSettingsModalForm from "./form/ProductsSettingsModalForm";
import doTask from "../../../../logic/UiManager";
import {createProductSettingField} from "../../../../logic/ProductsManager";

const ProductsSettingsModal: React.FC<ProductsSettingsModalTypes> = (props) => {


    const callCreateFieldSetting = async (body: object) => {
        await doTask(async () => {
            const res =  await createProductSettingField(body)
            if (res) {
                message.success(`Поле успешно добавлено`)
                props.onCloseModal()
            }
        })
    }

    const handleCreateFieldSetting  = () => {
        if (!props.editableProduct?.id) return
        props.form.validateFields().then(async (values: any) => {
            const body = {
                ...values,
                value: String(values.value),
                product: props.editableProduct?.id
            }
            await callCreateFieldSetting(body)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={'Добавить поле'}
                open={props.visible}
                onCancel={() => props.onCloseModal()}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            props.onCloseModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={async () => {
                            await handleCreateFieldSetting()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <ErrorBoundary>
                    <ProductsSettingsModalForm
                        form={props.form}
                        productSettingsList={props.productSettingsList}
                    />
                </ErrorBoundary>
            </Modal>
        </>
    )
}

export default ProductsSettingsModal

import React, {useCallback, useEffect} from "react";
import {Result} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {Box} from "@material-ui/core";
import {useLocation, useNavigate} from "react-router-dom";
import {getToken, setToken} from "../../logic/ApiManager";
import {login} from "../../logic/LoginManager";
import doTask from "../../logic/UiManager";
import useAsyncEffect from "use-async-effect";


const ContinuationAuthorizationPage: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const token = getToken()
        if (token) {
            navigate('/')
        }
    },[])

    useAsyncEffect(async isMounted => {
        if (!isMounted()) return;
        if (location?.search) {
            const authId = location?.search.split('=')[1]
            await callLogin(authId)
        } else {
            navigate('/')
        }
    }, [location]);


    const callLogin = useCallback(async (authId: string) => {
        if (!authId) return
        console.log('callLogin')
        await doTask(async () => {
            const res = await login(authId)
            if (res) {
                setToken(res)
                navigate('/')
            }
        })
    },[navigate])


    return (
        <>
            <Box style={{width: '100%',height: '100%',display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                <Result
                    icon={<LoadingOutlined style={{color: 'var(--primary-color)'}} />}
                    title="Подождите. Завершение авторизации"
                />
            </Box>
        </>
    )
}

export default ContinuationAuthorizationPage

import React from "react";
import {LeadModalFormPropsTypes} from "./LeadModalFormTypes";
import {Col, Form, Input, Row} from "antd";
import {InputMask} from "@react-input/mask";
import TextArea from "antd/es/input/TextArea";

const LeadModalForm: React.FC<LeadModalFormPropsTypes> = (props) => {

    return (
        <>
            <Form
                form={props.form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Название компании"}
                            name={"name"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Название компании"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Город"}
                            name={"city"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Город"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Контактное лицо (ФИО)"}
                            name={"contact_person"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Контактное лицо (ФИО)"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Телефон"}
                            name={"phone"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <InputMask
                                className={'base-and-input'}
                                placeholder={'Номер телефона'}
                                mask="+_ (___) ___ __ __" replacement={{ _: /\d/ }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Email"}
                            name={"email"}
                            rules={[
                                {required: true, message: "Обязательное поле!"},
                            ]}
                        >
                            <Input placeholder={"Email"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6} md={6}>
                        <Form.Item
                            label={"Комментарий"}
                            name={"comment"}
                        >
                            <TextArea style={{height: '32px'}} placeholder={"Комментарий"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );

}

export default LeadModalForm

import React, {useCallback, useState} from "react";
import cl from "./toolbar.module.css";
import classNames from 'classnames';
import {Button, Card, Col, Input, Row, Space, Tooltip} from "antd";
import {ToolbarTypes} from "./ToolbarTypes";
import {PlusOutlined, ReloadOutlined} from "@ant-design/icons";

const {Search} = Input;

const Toolbar: React.FC<ToolbarTypes> = (props) => {
    const [searchValue,setSearchValue] = useState<string | undefined>('')

    const handleChangeSearchValue = useCallback((searchValue: string | undefined) => {
        setSearchValue(searchValue)
        if (!searchValue) {
            handleUpdate('')
        }
    },[searchValue])

    const handleUpdate = useCallback((searchValue: string | undefined) => {
        props.handleUpdate(searchValue)
    },[searchValue])

    return (
        <>
            <Card bodyStyle={{padding: '15px'}} className={classNames(cl.toolbarCardWrap)}>
                <Row className={classNames(cl.toolbar)}>
                    <Col lg={20} md={16} sm={10} className={classNames(cl.searchInput)}>
                        <Search
                            placeholder={props.placeholder}
                            allowClear={true}
                            disabled={false}
                            enterButton={true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeSearchValue(e.target.value)
                            }}
                            onSearch={(value) => {
                                value.length > 0 &&  handleUpdate(value)
                            }}
                        />
                    </Col>
                    <Col>
                        <Space className={'button-add'}>
                            <Tooltip title={"Обновить"}>
                                <Button
                                    style={{margin: '0 10px',color: 'white'}}
                                    onClick={() => handleUpdate(searchValue)}
                                    type="text"
                                    icon={<ReloadOutlined/>}
                                />
                            </Tooltip>
                            <Button
                                icon={<PlusOutlined/>}
                                onClick={() => {
                                    props.openModal()
                                }}
                            >
                                Добавить
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default React.memo(Toolbar)

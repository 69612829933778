import React, {useCallback, useState} from 'react';
import ErrorBoundary from "../../../../../errorBoundary/ErrorBoundary";
import {Button, message, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {Box} from "@material-ui/core";
import ServersModal from "./modal/ServersModal";
import ServersList from "./serversList/ServersList";
import {ServersPropsTypes} from "./ServersTypes";
import {defaultFilters} from "../../../../../../logic/types/ApiTypes";
import doTask from "../../../../../../logic/UiManager";
import useAsyncEffect from "use-async-effect";
import {deleteProductServer, getProductServersList} from "../../../../../../logic/ProductsManager";
import {ProductServer} from "./serversList/ServersListTypes";
import useStateRef from 'react-usestateref'
import {
    calculationPageAfterActionFunction
} from "../../../../../../utils/pagination/calculationPageAfterActionFunction";

const Servers: React.FC<ServersPropsTypes> = (props) => {
    const [visibleModalServers, setVisibleModalServers] = useState<boolean>(false)
    const [serversList, setServersList,serversListRef] = useStateRef<ProductServer[]>([])
    const [editableServer,setEditableServer] = useState<ProductServer | null>(null)
    const [loading,setLoading] = useState<boolean>(false)
    const [page,setPage,pageRef] = useStateRef<number>(1)

    useAsyncEffect(async () => {
        await handleUpdateList(page)
    },[])

    const callGetServers = async(productId: number,params?: defaultFilters) => {
        await doTask(async () => {
            setLoading(true)
            const res = await getProductServersList(productId,params)
            if(res?.data)
                setServersList(res.data)

        }).finally(() => {
            setLoading(false)
        })
    }

    const handleUpdateList = useCallback(async (page: number) => {
        if (!props.productId) return
        setPage(page)
        const params: defaultFilters = {page}
        await callGetServers(props.productId,params)
    },[page])

    const handleOpenModalLicense = useCallback(() => {
        setVisibleModalServers(true)
    },[visibleModalServers])

    const handleCloseModalServers = useCallback(async () => {
        setVisibleModalServers(false)
        await handleUpdateList(page)
    },[visibleModalServers,page])


    const handleEditProductServer = useCallback((server: ProductServer) => {
        if (!server) return
        setEditableServer(server)
        setVisibleModalServers(true)
    },[editableServer, visibleModalServers])

    const handleDeleteProductServer = useCallback(async (productServerId: number) => {
        if (!props.productId || !productServerId)  return
        await doTask(async () => {
            if (!productServerId) return
            const res = await deleteProductServer(productServerId, props.productId)
            if (res) {
                message.success('Сервер успешно удален!')
                const pageFromUpdate = calculationPageAfterActionFunction(pageRef.current,serversListRef.current?.length)
                await handleUpdateList(pageFromUpdate)
            }
        })
    },[pageRef])

    return (
        <>
            <Box style={{minHeight: '371px', padding: '0 5px 5px 5px'}}>

                <Row justify={'end'}>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size={"middle"}
                        onClick={() => {
                            handleOpenModalLicense()
                        }}
                    >Добавить</Button>
                </Row>
                <ErrorBoundary>
                    <ServersList
                        loading={loading}
                        serversList={serversList}
                        handleEdit={(server: ProductServer) => {
                            handleEditProductServer(server)
                        }}
                        handleDeleteProductServer={async (productServerId: number) => {
                            await handleDeleteProductServer(productServerId)
                        }}
                    />
                </ErrorBoundary>

                <ErrorBoundary>
                    <ServersModal
                        editableServer={editableServer}
                        visible={visibleModalServers}
                        serversList={serversList}
                        productId={props.productId}
                        onCloseModal={async () => {
                            await handleCloseModalServers()
                        }}
                     />
                </ErrorBoundary>
            </Box>
        </>
    );
};

export default Servers;

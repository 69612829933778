import axios from 'axios'
import {restApiHost} from "../appVariables/appVariables";
import ApiUnauthorizedError from "../apiError/ApiUnauthorizedError";
import ApiError from "../apiError/ApiError";

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

const API_URL = restApiHost

async function _apiCall <T> (method: HttpMethods,
                             endpoint: string,
                             params: any = null,
                             additionalHeaders: any = {}
) {
    const token = getToken()
    let axiosParams: any = {
        method,
        url: API_URL + endpoint
    }

    if (params) {
        if (method === HttpMethods.GET) {
            axiosParams.params = params;
        } else {
            axiosParams.data = params;
        }
    }

    if (token) {
        axiosParams.headers = {
            Authorization: `Token ${token}`,
            ...additionalHeaders,
        }
    }
    let res

    res = await axios(axiosParams)

    if (res.status !== 200 && res.status !== 201) {
        let error_message = res?.data;
        if (res.status === 401 || error_message === 'Unauthenticated') {
            removeToken()
            throw new ApiUnauthorizedError(error_message, res.status);
        }

        throw new ApiError(error_message, res.status);
    }

    return res.data as T;
}


export async function apiCall <T> (method: HttpMethods,
                                    endpoint: string,
                                    params: any = null,
                                    havePermissionsViewBranch: boolean = false,
                                    addPaginationPageSize: boolean = false
): Promise<T> {
    const convertedParameters = addQueryParameters(params, addPaginationPageSize);

    return await _apiCall<T>(method, endpoint, convertedParameters)
}


const addQueryParameters = (params: any = null,
                            addPaginationPageSize: boolean = false): object => {

    const originalParams = params ? {...params} : {}
    /*if (havePermissionsViewBranch && !originalParams.hasOwnProperty('branch')) {
        const branch = getBranchId()
        if (branch) {
            originalParams['branch'] =  branch
        }
    }*/

    if (addPaginationPageSize && !originalParams.hasOwnProperty('size')) {
        originalParams['size'] = getCurrentPaginationPageSize()
    }

    return originalParams
}


export const getToken = () : string  | null => {
    return localStorage.getItem('qt-admin-token')
}

export const removeToken = () => {
    localStorage.removeItem('qt-admin-token')
}

export const setToken = (token: string) => {
    if (!token) return
    localStorage.setItem('qt-admin-token',token)
}

/*const getBranchId = () : string | null => {
    return  localStorage.getItem('branchId')
}*/

const getCurrentPaginationPageSize = () : string | null  => {
    return localStorage.getItem('paginationPageSize') ? localStorage.getItem('paginationPageSize') : '15' as string;
}

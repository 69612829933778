import React from "react";
import {LicensesListPropsTypes} from "./LicensesListTypes";
import {List, Pagination} from "antd";
import {License} from "../LicensesTypes";
import {Box} from "@material-ui/core";
import LicenseItem from "./licenseItem/LicenseItem";
import cl from './license.module.css'

const LicensesList: React.FC<LicensesListPropsTypes> = (props) => {

    return (
        <>
            <Box
                className={cl.box}
            >
                <Box
                    className={cl.boxBody}
                >
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        loading={props.loading}
                        dataSource={props.licensesList}
                        renderItem={(license: License, index) => (
                            <List.Item>
                                <LicenseItem
                                    license={license}
                                    key={index}
                                    handleCustomizableLicense={(licenseId: number) => {
                                        props.handleCustomizableLicense && props.handleCustomizableLicense(licenseId)
                                    }}
                                    handleTransitionProductLicense={(licenseId: number) => {
                                        props.handleTransitionProductLicense && props.handleTransitionProductLicense(licenseId)
                                    }}
                                    handleTerminateLicense={(licenseId: number) => {
                                        props.handleTerminateLicense && props.handleTerminateLicense(licenseId)
                                    }}
                                    handleEditPeriodLicense={(licenseId: number) => {
                                        props.handleEditPeriodLicense && props.handleEditPeriodLicense(licenseId)
                                    }}
                                />
                            </List.Item>
                        )}
                    />
                </Box>
            </Box>
            {props.totalLicenses >= 15 ?
                <Box className={cl.boxPagination}>
                    <Pagination
                        className={cl.pagination}
                        total={props.totalLicenses}
                        current={props.page}
                    />
                </Box> : <></>}
        </>
    )
}

export default LicensesList

import React from 'react';
import {Box} from "@material-ui/core";
import {List} from "antd";
import {ProductServer, ServersListPropsTypes} from "./ServersListTypes";
import ServersItem from "./serversItem/ServersItem";
import cl from "./servers.module.css"

const ServersList: React.FC<ServersListPropsTypes> = (props) => {
    return (
        <>
            <Box
                className={cl.box}
            >
                <Box
                    className={cl.boxBody}
                >
                    <List
                        grid={{ gutter: 16, column: 1 }}
                        loading={props.loading}
                        dataSource={props.serversList}
                        renderItem={(server: ProductServer, index) => (
                            <List.Item>
                                <ServersItem
                                    server={server}
                                    key={index}
                                    handleEdit={(server: ProductServer) => {
                                        props.handleEdit && props.handleEdit(server)
                                    }}
                                    handleDelete={(serverId: number) => {
                                        props.handleDeleteProductServer && props.handleDeleteProductServer(serverId)
                                    }}
                                />
                            </List.Item>
                        )}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ServersList;

import React, {useCallback, useMemo, useState} from "react";
import {
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Space,
    Switch,
    Table,
    TablePaginationConfig,
    Tooltip,
    Typography
} from "antd";
import {
    FieldSetting
} from "../../../partners/modal/mainModal/tabsModal/licenses/licensesSettings/form/LicensesSettingsFormTypes";
import {EditableTableProductsSettingsPropsTypes} from "./EditableTableProductsSettingsTypes";
import moment from "moment/moment";
import {tablePaginationConfig} from "../../../users/table/UsersTableTypes";
import {setPaginationFunction} from "../../../../utils/pagination/setPaginationFunction";
import ErrorBoundary from "../../../errorBoundary/ErrorBoundary";
import DropdownActions from "../../../dropdown/dropdownMenu/DropdownActions";

export const getInputNodeComponent = (inputType: string,placeholder?: string) => {
    switch(inputType) {
        case "str":
            return (
                <Input placeholder={placeholder} style={{width: '100%'}}/>
            )
        case "int":
            return (
                <InputNumber placeholder={placeholder} style={{width: '100%'}}/>
            )
        case "bool":
            return (
                <Switch/>
            )
        case "datetime":
            return (
                <input
                    placeholder={placeholder}
                    className={'ant-input base-antd-date-picker'}
                    type="date"
                    max="9999-12-31"
                    min={moment().format("DD-MM-YYYY")}
                    pattern="[0-2][0-9]"
                />
            )
        default:
            return (
                <Input />
            )
    }
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    width: string,
    ellipsis: object,
    align?: string,
    inputType: 'number' | 'text';
    record: FieldSetting;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       editing,
                                                       dataIndex,
                                                       title,
                                                       inputType,
                                                       record,
                                                       index,
                                                       children,
                                                       align,
                                                       ...restProps
                                                   }) => {
    const inputNode = getInputNodeComponent(inputType,title)

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Обязательное поле`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const EditableTableProductsSettings: React.FC<EditableTableProductsSettingsPropsTypes> = (props) => {
    const [editingKey, setEditingKey] = useState<number | null | undefined>(null);
    const isEditing = (record: FieldSetting) => record?.id === editingKey

    const handleCancelEdit = useCallback(() => {
        props.form?.resetFields()
        setEditingKey(null);
    },[editingKey])

    const handleEditRecord = useCallback((record: FieldSetting) => {
        props.form.setFieldsValue({
            ...record,
            value: record.type === 'bool' && record.value !== undefined ?  JSON.parse(record.value) : record.value
        });
        setEditingKey(record?.id);
    },[editingKey])

    const columns = [
        {
            title: 'Действия',
            dataIndex: 'operation',
            width: '150px',
            align: "center" as const,
            render: (_: any, record: FieldSetting) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => {
                    props.handleEditFieldSetting && props.handleEditFieldSetting(record?.id,record.type)
                    setEditingKey(null);
                }}
                style={{
                    marginRight: 8,
                }}
            >
              Сохранить
            </Typography.Link>
            <Popconfirm title="Действительно отменить?" onConfirm={handleCancelEdit}>
              <a>Отменить</a>
            </Popconfirm>
          </span>
                ) : (
                    <>
                      {/*  <Typography.Link disabled={editingKey !== null} onClick={() => handleEditRecord(record)}>
                            Редактировать
                        </Typography.Link>*/}
                        <Space size="middle">
                            <ErrorBoundary>
                                <DropdownActions
                                    recordId={record.id}
                                    disabled={editingKey !== null}
                                    handleEdit={() => {
                                        handleEditRecord(record)
                                    }}
                                    handelDelete={(recordId: number) => {
                                        props.handleDelete && props.handleDelete(recordId)
                                    }}/>
                            </ErrorBoundary>
                        </Space>
                    </>
                );
            },
        },
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            width: '180px',
            editable: true,
            ellipsis: {
                showTitle: false
            },
            render: (title: string) => {
                return (
                    <Tooltip title={title}>
                        {title}
                    </Tooltip>
                )
            }
        },
        {
            title: 'Ключ',
            dataIndex: 'key',
            key: 'key',
            width: '180px',
            editable: true,
            ellipsis: {
                showTitle: false
            },
            render: (key: string) => {
                return (
                    <Tooltip title={key}>
                        {key}
                    </Tooltip>
                )
            }
        },
        {
            title: 'Значение',
           // dataIndex: 'value',
            editable: true,
            width: '200px',
            key: 'value',
            ellipsis: {
                showTitle: false
            },
            render: (col: FieldSetting) => {
                return (
                    <>
                        {/*TODO: переписать это по красоте (PS: для теста пока что так)*/}
                        {col?.type === 'bool' && col?.value !== undefined  ?
                            <Switch disabled={true} checked={JSON.parse(col.value)} /> :
                            <Tooltip title={col?.type === 'datetime' && col.value === 'null' ? 'Действие прекращено' : col.value && col?.type === 'datetime' ? moment(col.value).format('DD.MM.YYYY') : col.value}>
                                {col?.type === 'datetime' && col.value === 'null' ? 'Действие прекращено' : col.value && col?.type === 'datetime' ? moment(col.value).format('DD.MM.YYYY') : col.value}
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            width: '100px',
            editable: !props.hideKeyColumn,
            ellipsis: {
                showTitle: false
            },
            render: (type: string) => {
                return (
                    <Tooltip title={type}>
                        {type}
                    </Tooltip>
                )
            }
        },
    ]

    const mergedColumns = useMemo(() => {
        const filteredColumns = props.hideKeyColumn ? columns.filter((col) => col.key !== 'key') : [...columns]
       return  filteredColumns.map((col) => {
            if (!col.editable) {
                return col
            }

            return {
                ...col,
                onCell: (record: FieldSetting) => ({
                    record,
                    inputType: col?.key === 'value' ? record?.type : 'text',
                    dataIndex: col.dataIndex || col.key,
                    title: col.title,
                    width: col.width,
                    align: col?.align,
                    ellipsis: {showTitle: col?.ellipsis.showTitle},
                    editing: isEditing(record),
                }),
            };
        })
    },[columns,editingKey,isEditing, props.productSettingsList])

    const handleChangePaginationTable = useCallback((paginationConfig: tablePaginationConfig) => {
        handleCancelEdit()
        props.handleUpdateList && props.handleUpdateList(paginationConfig.current)
    },[props.handleUpdateList])

    const filteringKeSitting = useMemo(() => {
      const hideSettingsKeysListCopy = props.hideSettingsKeysList ? props.hideSettingsKeysList : []
        return props.productSettingsList.filter((field: FieldSetting) => !hideSettingsKeysListCopy.includes(field.key))
    },[props.productSettingsList,props.hideSettingsKeysList])


    return (
        <>
            <Form form={props.form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    onRow={(record: FieldSetting) => {
                        return {
                            onDoubleClick: () => {
                                handleEditRecord(record)
                            },
                        };
                    }}
                    loading={props.loading}
                    dataSource={filteringKeSitting}
                    columns={mergedColumns}
                    onChange={(pagination: TablePaginationConfig) => handleChangePaginationTable(pagination as tablePaginationConfig) }
                    pagination={setPaginationFunction(props.hideSettingsKeysList ?
                                                            props.total - props.hideSettingsKeysList.length :
                                                                props.total, props.page)}
                    rowClassName="editable-row"
                    size="small"
                />
            </Form>
        </>
    )
}

export default EditableTableProductsSettings
